import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { mapStateToProps, CaptionProps } from "./CaptionTypes";
import { addMessage } from "store/chat/actions";
import Caption from "./Caption";
import RtmInterface from "../../../services/agora/rtm";

const CaptionConatiner: React.FC<{}> = (): JSX.Element => {
  const {
    captions,
    isCallJoined,
    speechRecognitionService,
    mode,
    isAnotherJoined,
  } = useSelector(mapStateToProps, shallowEqual);

  const dispatch = useDispatch();
  const [lastMessageId, setLastMessageId] = useState("");
  const [newMessage, setNewMessage] = useState({ id: "", value: "" });

  useEffect(() => {
    if (newMessage.value) {
      dispatch(addMessage({ key: newMessage.id, value: newMessage.value }));
    }
    if (lastMessageId === "") {
      setLastMessageId(newMessage.id);
    } else if (lastMessageId !== newMessage.id) {
      setLastMessageId(newMessage.id);
    }

    if (isAnotherJoined) {
      try {
        RtmInterface.channelSendMessage(newMessage.value, newMessage.id);
      } catch (e) {
        console.error("RTM ERROR:" + e);
      }
    }
  }, [newMessage, dispatch, lastMessageId, isAnotherJoined]);

  useEffect(() => {
    if (isCallJoined && isAnotherJoined && RtmInterface.channel !== undefined) {
      RtmInterface.channel.on(
        "ChannelMessage",
        ({ text }: { text: string }) => {
          const message = text.slice(36, text.length);
          const id = text.slice(0, 36);
          dispatch(
            addMessage({
              key: "[" + id + "]",
              value: message.replace(/\[.*\]/, ""),
            })
          );
        }
      );
    }
  }, [isCallJoined, isAnotherJoined, dispatch]);

  useEffect(() => {
    speechRecognitionService !== undefined &&
      speechRecognitionService.onResult(
        (result: string, messageId: string): void => {
          setNewMessage({ id: messageId, value: result });
        }
      );

    return (): void => {
      speechRecognitionService !== undefined && speechRecognitionService.stop();
    };
  }, [speechRecognitionService]);

  const captionProps: CaptionProps = {
    mode,
    captions,
    isCallJoined,
  };

  return <Caption {...captionProps} />;
};

export default CaptionConatiner;
