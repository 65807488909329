/* eslint-disable @typescript-eslint/no-this-alias */
import AgoraRTC, { Stream } from "agora-rtc-sdk";
import * as UUID from "uuid";
const agoraClient = AgoraRTC.createClient({
  mode: "live",
  codec: "h264",
});
const USER_ID: string = UUID.v4();
// eslint-disable-next-line no-undef
const APP_ID: string = process.env.REACT_APP_AGORA_API_KEY || "";

class StreamInterface {
  localStream: Stream = AgoraRTC.createStream({
    streamID: USER_ID,
    audio: true,
    video: true,
    screen: false,
  });
  remoteStreams: Stream[] = [];
  client = agoraClient;
  channel = "";
  logged = false;

  initLocalStream(): void {
    if (!this.logged) {
      if (document.getElementById("agora_local")) {
        this.localStream.init(
          () => {
            console.log("getUserMedia successfully");
            this.logged = true;
            document.getElementById("agora_local") &&
              this.localStream.play("agora_local");
          },
          (err) => {
            console.log("getUserMedia failed", err);
          }
        );
      } else {
        console.log("Camera turned off");
      }
    }
  }

  playStream = (): void => {
    if (document.getElementById("agora_local")) {
      this.localStream.play("agora_local");
    }
  };
  stopStream = (): void => {
    if (document.getElementById("agora_local")) {
      this.localStream.stop(/*"agora_local"*/);
    }
  };

  initClient(): void {
    agoraClient.init(
      APP_ID,
      function () {
        console.log("AgoraRTC client initialized");
      },
      function (err) {
        console.log("AgoraRTC client init failed", err);
      }
    );
    this.subscribeToClient();
  }

  joinChannel(): void {
    const me = this;
    agoraClient.join(
      null,
      me.channel,
      USER_ID,
      function (uid: string | number) {
        console.log("User " + uid + " join channel successfully");
        me.publishStream();
      },
      function (err) {
        console.log("Join channel failed", err);
      }
    );
  }

  publishStream(): void {
    const me = this;
    agoraClient.publish(me.localStream, function (err) {
      console.log("Publish local stream error: " + err);
    });
  }

  setChannel = (name: string): void => {
    this.channel = name;
  };

  getRemoteStream = (): Stream[] => {
    return this.remoteStreams;
  };

  getConnectionState = (): string => {
    return this.client.getConnectionState();
  };

  private subscribeToClient(): void {
    const me = this;
    agoraClient.on("stream-added", me.onStreamAdded);
    agoraClient.on("stream-subscribed", me.onRemoteClientAdded);
    agoraClient.on("stream-removed", me.onStreamRemoved);
    agoraClient.on("peer-leave", me.onPeerLeave);
    agoraClient.on("peer-online", me.onPeerOnline);
    // client.on("mute-video", me.onMuteVideo);
  }

  private onStreamAdded = (evt: any): void => {
    const me = this;
    const stream = evt.stream;
    console.log("New stream added: " + stream.getId());
    me.remoteStreams = { ...me.remoteStreams, [stream.getId()]: stream };
    agoraClient.subscribe(
      stream,
      {
        audio: true,
        video: true,
      },
      function (err) {
        console.log("Subscribe stream failed", err);
      }
    );
  };

  private onPeerOnline = (): void => {
    // Triggers the "volume-indicator" callback event every two seconds.
    agoraClient.enableAudioVolumeIndicator();
  };

  private onRemoteClientAdded = (evt: any): void => {
    const me = this;
    const remoteStream = evt.stream;
    if (me.remoteStreams[remoteStream.getId()]) {
      me.remoteStreams[remoteStream.getId()].play("agora_remote");
    }
  };

  private onStreamRemoved = (evt: any): void => {
    const me = this;
    const stream = evt.stream;
    if (stream) {
      const streamId = stream.getId();
      const { remoteStreams } = me;
      stream.stop();
      delete remoteStreams[streamId];
      console.log("Remote stream is removed " + stream.getId());
    }
  };

  private onPeerLeave = (evt): void => {
    const me = this;
    const stream = evt.stream;
    if (stream) {
      stream.stop();
      me.remoteStreams = [];
      console.log(evt.uid + " leaved from this channel");
    }
  };
}

export default new StreamInterface();
