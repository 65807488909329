import { AppState } from "store";

interface StateProps {
  dotColor: string;
}

export interface StyleProps {
  dotColor: string;
}

export const mapStateToProps = (state: AppState): StateProps => {
  return {
    dotColor: state.globalTheme.customCaptions.fontColor,
  };
};
