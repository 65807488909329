import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme } from "@material-ui/core/styles";

import MainPageContainer from "./components/MainPage/MainPageContainer";
import CallPageContainer from "./components/CallPage/CallPageContainer";
import { Props, mapStateToProps } from "./AppTypes";
import { connect } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";

class App extends Component<Props, {}> {
  public render(): JSX.Element {
    const theme = createMuiTheme(this.props.globalTheme);
    return (
      <React.Fragment>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <Switch>
              <Route path="/" exact component={MainPageContainer} />
              <Route path="/call" component={CallPageContainer} />
            </Switch>
          </Router>
        </ThemeProvider>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, null)(App);
