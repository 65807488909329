import React from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";

interface Props {
  isMobile: boolean;
  slideNumber: number;
}
const useStyles = makeStyles(() => ({
  titleDesktopText: {
    width: "420px",
    textAlign: "center",
    fontWeight: 100,
  },
  descriptionDesktopText: {
    width: "350px",
    textAlign: "center",
    margin: "0 auto",
    color: "rgba(129,136,149,1)",
    marginTop: "20px",
    fontWeight: 100,
  },
}));

const OnboardCarouselHeader = ({
  isMobile,
  slideNumber,
}: Props): JSX.Element => {
  const classes = useStyles();
  const [t] = useTranslation();
  return (
    <>
      {slideNumber === 0 && (
        <>
          <Typography
            variant={isMobile ? "h5" : "h4"}
            className={!isMobile ? classes.titleDesktopText : ""}
          >
            {t("OnboardCarousel.improveFlowTitle")}
          </Typography>
          <Typography
            variant={isMobile ? "body1" : "h6"}
            className={!isMobile ? classes.descriptionDesktopText : ""}
          >
            {t("OnboardCarousel.improveFlowDescription")}
          </Typography>
        </>
      )}
      {slideNumber === 1 && (
        <>
          <Typography
            variant={isMobile ? "h5" : "h4"}
            className={!isMobile ? classes.titleDesktopText : ""}
          >
            {t("OnboardCarousel.createRoomTitle")}
          </Typography>
          <Typography
            variant={isMobile ? "body1" : "h6"}
            className={!isMobile ? classes.descriptionDesktopText : ""}
          >
            {t("OnboardCarousel.createRoomDescription")}
          </Typography>
        </>
      )}
      {slideNumber === 2 && (
        <>
          <Typography
            variant={isMobile ? "h5" : "h4"}
            className={!isMobile ? classes.titleDesktopText : ""}
          >
            {t("OnboardCarousel.inviteFriendTitle")}
          </Typography>
          <Typography
            variant={isMobile ? "body1" : "h6"}
            className={!isMobile ? classes.descriptionDesktopText : ""}
          >
            {t("OnboardCarousel.inviteFriendDescription")}
          </Typography>
        </>
      )}
      {slideNumber === 3 && (
        <>
          <Typography
            variant={isMobile ? "h5" : "h4"}
            className={!isMobile ? classes.titleDesktopText : ""}
          >
            {t("OnboardCarousel.videoChatTitle")}
          </Typography>
          <Typography
            variant={isMobile ? "body1" : "h6"}
            className={!isMobile ? classes.descriptionDesktopText : ""}
          >
            {t("OnboardCarousel.videoChatDescription")}
          </Typography>
        </>
      )}
      {slideNumber === 4 && (
        <>
          <Typography
            variant={isMobile ? "h5" : "h4"}
            className={!isMobile ? classes.titleDesktopText : ""}
          >
            {t("OnboardCarousel.settingsTitle")}
          </Typography>
          <Typography
            variant={isMobile ? "body1" : "h6"}
            className={!isMobile ? classes.descriptionDesktopText : ""}
          >
            {t("OnboardCarousel.settingsDescription")}
          </Typography>
        </>
      )}
    </>
  );
};

export { OnboardCarouselHeader };
