import {
  CHANGE_PERMISSION_DIALOG_VISIBILITY,
  CHANGE_ONBOARDING,
  CHANGE_TIPS,
  CHANGE_MODE,
  SET_SPLASH_SCREEN,
  CHANGE_CAPTIONS_LIST_HEIGHT,
  CHANGE_UNAVAILABLE_LINK_DIALOG,
  CHANGE_OCCUPIED_CONVERSATION_DIALOG,
  CHANGE_BROWSER_DIALOG,
} from "../constants/types";

import {
  ChangePermissionDialogVisibilityAction,
  ChangeOnboardingAction,
  ChangeTipsAction,
  ChangeModeAction,
  SetSplashScreenAction,
  ChanegCaptionsListHeight,
  ChangeOccupiedConversationDialog,
  ChangeUnavailableDialog,
  ChangeBrowserDialog,
} from "../constants/types";

export function changePermissionDialogVisibility(
  payload: ChangePermissionDialogVisibilityAction["payload"]
): ChangePermissionDialogVisibilityAction {
  return {
    type: CHANGE_PERMISSION_DIALOG_VISIBILITY,
    payload,
  };
}

export function changeOnboarding(
  payload: ChangeOnboardingAction["payload"]
): ChangeOnboardingAction {
  return {
    type: CHANGE_ONBOARDING,
    payload,
  };
}

export function changeTips(
  payload: ChangeTipsAction["payload"]
): ChangeTipsAction {
  return {
    type: CHANGE_TIPS,
    payload,
  };
}

export function changeMode(
  payload: ChangeModeAction["payload"]
): ChangeModeAction {
  return {
    type: CHANGE_MODE,
    payload,
  };
}

export function setSplashScreen(
  payload: SetSplashScreenAction["payload"]
): SetSplashScreenAction {
  return {
    type: SET_SPLASH_SCREEN,
    payload,
  };
}

export function changeCaptionsListHeight(
  payload: ChanegCaptionsListHeight["payload"]
): ChanegCaptionsListHeight {
  return {
    type: CHANGE_CAPTIONS_LIST_HEIGHT,
    payload,
  };
}

export function changeUnavailebleLinkDialog(
  payload: ChangeUnavailableDialog["payload"]
): ChangeUnavailableDialog {
  return {
    type: CHANGE_UNAVAILABLE_LINK_DIALOG,
    payload,
  };
}

export function changeOccupiedConversationDialog(
  payload: ChangeOccupiedConversationDialog["payload"]
): ChangeOccupiedConversationDialog {
  return {
    type: CHANGE_OCCUPIED_CONVERSATION_DIALOG,
    payload,
  };
}

export function changeBrowserDialog(
  payload: ChangeBrowserDialog["payload"]
): ChangeBrowserDialog {
  return {
    type: CHANGE_BROWSER_DIALOG,
    payload,
  };
}
