/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import copy from "copy-to-clipboard";
import { useTranslation } from "react-i18next";
import ReactSVG from "react-svg";

import { makeStyles } from "@material-ui/styles";
import {
  Dialog,
  Typography,
  Button,
  Theme,
  useMediaQuery,
  Checkbox,
} from "@material-ui/core";

import CustomSnackbar from "./CustomSnackbar";

import invitationIcon from "img/Invitation.svg";
import closeIcon from "img/Close.svg";
import copyIcon from "img/Copy-clipboard.svg";

interface StyleProps {
  isMobile: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  root: (props: StyleProps) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: props.isMobile ? "470px" : "703px",
    width: props.isMobile ? "auto" : "596px",
    justifyContent: "center",
  }),

  dialog: {
    "&> .MuiBackdrop-root": {
      backgroundColor: "rgba(11, 8, 27, 0.9)",
    },
  },
  closeIcon: {
    alignSelf: "flex-end",
    position: "absolute",
    top: 0,
    padding: "15px",
    "&:hover": {
      cursor: "pointer",
    },
    "& svg g": {
      fill: "#818895",
    },
  },
  invitationIcon: (props: StyleProps) => ({
    width: props.isMobile ? "90px" : "20vh",
    margin: "0 auto",
    "& svg": {
      height: props.isMobile ? "90px" : "180px",
      width: props.isMobile ? "90px" : "180px",
    },
    "&> div": {
      height: props.isMobile ? "90px" : "180px",
      width: props.isMobile ? "90px" : "180px",
      display: "flex",
    },
  }),
  title: (props: StyleProps) => ({
    color: "#3F4756",
    margin: props.isMobile ? "12px 0" : "5vh 0 5vh 0",
    textAlign: "center",
    fontSize: props.isMobile ? "22px" : "36px",
    lineHeight: "32px",
  }),
  linkTitle: {
    fontSize: "12px",
    color: "rgba(129,136,149,1)",
    marginLeft: "20px",
    alignSelf: "flex-start",
  },
  link: {
    border: "1px solid rgba(238,238,238,1)",
    borderRadius: "8px",
    margin: "2px 20px 20px 20px",
    padding: "10px 20px",
  },
  linkText: {
    textAlign: "center",
    color: "rgba(63,71,86,1)",
    wordBreak: "break-all",
  },
  linkCopy: {
    display: "flex",
    marginTop: "10px",
    "&:hover": {
      cursor: "pointer",
    },
    width: "fit-content",
    marginLeft: "auto",
    marginRight: "auto",
  },
  linkCopyText: {
    marginLeft: "5px",
    color: "rgba(129,136,149,1)",
    fontSize: "12px",
  },
  sendButton: {
    height: "50px",
    width: "calc(100% - 40px)",
    backgroundColor: "rgba(131,108,242,1)",
  },
  sendText: {
    color: theme.palette.primary.light,
  },
  enableTranscription: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    lineHeight: "42px",
    margin: "20px 0",
  },
  enableTranscriptionCheckBox: {
    marginLeft: "20px",
  },
}));

export const ShareDialog: React.FC<{ open: boolean; date: Date }> = ({
  open,
  date,
}): JSX.Element => {
  const [isOpen, setOpen] = useState(open);
  const [t] = useTranslation();
  const [snackbar, setSnackbar] = useState({
    message: "",
    date: new Date(),
    open: false,
    icon: "",
    isClosable: false,
  });

  const [transcriptionEnabled, setTranscriptionEnabled] = React.useState(false);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const classes = useStyles({ isMobile: isMobile });

  useEffect(() => setOpen(open), [open, date]);

  const returnInvitationLink = (): string => {
    const invitationLink: URL = new URL(window.location.href);
    invitationLink.searchParams.set(
      "transcription",
      transcriptionEnabled.toString()
    );
    return invitationLink.toString();
  };

  const copyLink = (): void => {
    copy(returnInvitationLink());
    setSnackbar({
      date: new Date(),
      message: t("ShareDialog.linkCopied"),
      open: true,
      icon: copyIcon,
      isClosable: true,
    });
    setOpen(false);
  };

  const shareMobile = (): void => {
    (navigator as any).share({
      title: t("ShareDialog.shareMessageTitle"),
      text: t("ShareDialog.shareMessageText"),
      url: returnInvitationLink(),
    });
  };

  const handleSend = (): void => {
    if ((navigator as any).share) {
      shareMobile();
    }
    setOpen(false);
  };

  const handleTranscriptionChange = (): void => {
    setTranscriptionEnabled(!transcriptionEnabled);
  };

  return (
    <React.Fragment>
      <CustomSnackbar
        date={snackbar.date}
        message={snackbar.message}
        open={snackbar.open}
        icon={snackbar.icon}
        isClosable={snackbar.isClosable}
      />
      <Dialog
        className={classes.dialog}
        open={isOpen}
        onClose={(): void => setOpen(false)}
      >
        <div className={classes.root}>
          <ReactSVG
            src={closeIcon}
            className={classes.closeIcon}
            onClick={(): void => {
              setOpen(false);
            }}
          />
          <div>
            <ReactSVG src={invitationIcon} className={classes.invitationIcon} />
            <Typography variant="h5" className={classes.title}>
              {t("ShareDialog.sendInvitation")}
            </Typography>
          </div>
          <div>
            <Typography className={classes.linkTitle}>
              {t("ShareDialog.linkTitle")}
            </Typography>
            <div className={classes.link}>
              <Typography variant="body2" className={classes.linkText}>
                {returnInvitationLink()}
              </Typography>
            </div>
            <div className={classes.linkCopy} onClick={copyLink}>
              <ReactSVG src={copyIcon} />
              <Typography className={classes.linkCopyText}>
                {t("ShareDialog.linkCopy")}
              </Typography>
            </div>
            <div className={classes.enableTranscription}>
              <Typography>{t("ShareDialog.enableTranscription")}</Typography>
              <Checkbox
                className={classes.enableTranscriptionCheckBox}
                checked={transcriptionEnabled}
                onChange={handleTranscriptionChange}
                value="checked"
                inputProps={{
                  "aria-label": "primary checkbox",
                }}
              />
            </div>
          </div>
          {isMobile && (
            <Button className={classes.sendButton} onClick={handleSend}>
              <Typography className={classes.sendText} variant="body1">
                {t("ShareDialog.sendInvitation")}
              </Typography>
            </Button>
          )}
        </div>
      </Dialog>
    </React.Fragment>
  );
};

ShareDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
};

ShareDialog.defaultProps = {
  open: false,
};
