import { store } from "store";
import { changePermissionDialogVisibility } from "store/layout/actions";

export default class PermissionService {
  private localVideoTrackId = "";
  private localAudioTrackId = "";

  public checkPermissions(kind: "microphone" | "camera"): void {
    (navigator as any).permissions
      .query({ name: kind })
      .then(function (status: any): void {
        switch (status.state) {
          case "denied":
            store.dispatch<any>(
              changePermissionDialogVisibility({ isPermissionDialog: true })
            );
            break;
        }
      });
  }
}
