import * as UUID from "uuid";
import * as log from "loglevel";
log.setLevel("debug");

const SpeechRecognition: any =
  window.SpeechRecognition || window.webkitSpeechRecognition;
const SpeechRecognitionEvent: any =
  window.SpeechRecognitionEvent || window.webkitSpeechRecognitionEvent;

const CHARS_PER_MESSAGE = 60; // number of characters per bubble
const CHARS_PER_MESSAGE_THRESHOLD = 120; //  max length of previous bubble
const NEW_BUBBLE_TIMER_SEC = 5; // max silent time beetwen buubles

export default class SpeechRecognitionService {
  private speechRecognition: SpeechRecognition;
  private messageId: string;
  private isSpeechRecognition: boolean;
  private charNumberThreshold = 0;
  private charNumberThresholdStep = 10;

  private lastMessage: string;
  private timer = 0;

  public constructor() {
    this.speechRecognition = new SpeechRecognition();
    this.speechRecognition.lang = "pl-PL";
    this.speechRecognition.interimResults = true;
    this.speechRecognition.continuous = false;
    this.speechRecognition.maxAlternatives = 1;
    this.messageId = UUID.v4();
    this.isSpeechRecognition = false;

    this.lastMessage = "";

    this.initializeSpeechRecognitionListeners();
  }

  private startTimer(): void {
    this.timer = window.setInterval((): void => {
      this.lastMessage = "";
      this.stopTimer();
      this.messageId = UUID.v4();
    }, NEW_BUBBLE_TIMER_SEC * 1000);
  }

  private stopTimer(): void {
    clearInterval(this.timer);
  }

  private initializeSpeechRecognitionListeners(): void {
    this.speechRecognition.onstart = (): void => {
      this.isSpeechRecognition = true;
    };

    this.speechRecognition.onerror = (event: SpeechRecognitionError): void => {
      this.isSpeechRecognition = event.error === "no-speech" ? true : false;
    };
    this.speechRecognition.onend = (): void => {
      if (this.isSpeechRecognition) {
        this.speechRecognition.start();
      }
    };
  }

  private firstCharToUpperCase(text: string): string {
    const arrayText = text.split("");
    arrayText.length > 0
      ? (arrayText[0] = arrayText[0].toUpperCase())
      : arrayText.push("");
    return arrayText.join("");
  }

  public onResult = (
    callback: (result: string, messageId: string) => void
  ): void => {
    this.speechRecognition.onresult = (event: SpeechRecognitionEvent): void => {
      if (this.lastMessage.length > CHARS_PER_MESSAGE_THRESHOLD) {
        this.messageId = UUID.v4();
        this.lastMessage = "";
      }
      let interimTranscript = "";
      this.stopTimer();
      for (let i = event.resultIndex; i < event.results.length; i++) {
        const transcript = event.results[i][0].transcript;
        if (event.results[i].isFinal) {
          const newTranscript = this.firstCharToUpperCase(transcript) + ". ";
          log.debug("FINAL: " + newTranscript);
          this.lastMessage += newTranscript;
          this.startTimer();
          this.charNumberThreshold = 0;
          if (this.isSpeechRecognition)
            callback(this.lastMessage, this.messageId);
        } else {
          if (transcript !== "" && interimTranscript === "") {
            interimTranscript += this.firstCharToUpperCase(transcript);
          } else {
            interimTranscript += transcript;
          }
        }
      }

      if (interimTranscript !== "") {
        if (
          this.lastMessage !== "" &&
          interimTranscript.length + this.lastMessage.length > CHARS_PER_MESSAGE
        ) {
          if (this.isSpeechRecognition)
            callback(this.lastMessage, this.messageId);
          this.lastMessage = "";
          this.messageId = UUID.v4();
        } else if (interimTranscript.length > this.charNumberThreshold) {
          this.charNumberThreshold += this.charNumberThresholdStep;
          if (this.isSpeechRecognition)
            callback(this.lastMessage + interimTranscript, this.messageId);
        }
      }
    };
  };

  public start = (): void => {
    if (!this.isSpeechRecognition) {
      this.speechRecognition.start();
    }
  };

  public stop = (): void => {
    if (this.isSpeechRecognition) {
      this.isSpeechRecognition = false;
      this.speechRecognition.abort();
    }
  };
}
