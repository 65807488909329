import {
  GlobalThemeState,
  GlobalThemeActionTypes,
  TOOGLE_DARK_MODE,
  CHANGE_FONT_SIZE,
  CHANGE_FONT_FAMILY,
  CHANGE_FONT_COLOR,
  CHANGE_OTHER_BUBBLE_COLOR,
} from "../constants/types";

export const initialGlobalThemeState: GlobalThemeState = {
  palette: {
    type: "light",
    primary: {
      light: "#FFFFFF",
      main: "#FFFFFF",
      dark: "#3F4756",
    },
    secondary: {
      light: "rgba(131,108,242,0.8)",
      main: "#3F4756",
      dark: "rgba(0,0,0,0.4)",
    },
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundImage: "linear-gradient(135deg, #FF71A3 0%, #FFA0A7 100%);",
      },
    },
  },
  customPalette: {
    otherBubble: {
      main: "rgba(131,108,242,0.8)",
    },
    myBubble: {
      main: "rgba(0,0,0,0.4)",
    },
  },
  customCaptions: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontColor: "rgba(255,255,255,1)",
  },
};

export default function globalThemeReducer(
  state = initialGlobalThemeState,
  action: GlobalThemeActionTypes
): GlobalThemeState {
  if (action.type === TOOGLE_DARK_MODE) {
    const newPalette = Object.assign({}, state.palette, action.payload);
    return Object.assign({}, state, { palette: newPalette });
  }
  if (action.type === CHANGE_FONT_SIZE) {
    return Object.assign({}, state, {
      customCaptions: {
        ...state.customCaptions,
        ...action.payload,
      },
    });
  }
  if (action.type === CHANGE_FONT_FAMILY) {
    return Object.assign({}, state, {
      customCaptions: {
        ...state.customCaptions,
        ...action.payload,
      },
    });
  }
  if (action.type === CHANGE_FONT_COLOR) {
    return Object.assign({}, state, {
      customCaptions: {
        ...state.customCaptions,
        ...action.payload,
      },
    });
  }
  if (action.type === CHANGE_OTHER_BUBBLE_COLOR) {
    return Object.assign({}, state, {
      customPalette: {
        ...state.customPalette,
        ...action.payload,
      },
    });
  }
  return state;
}
