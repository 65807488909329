import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Snackbar, SnackbarContent, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import closeIcon from "img/Close.svg";
import ReactSVG from "react-svg";

export interface StyleProps {
  isPurple: boolean | undefined;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  snackbarRoot: {
    marginTop: "10px",
    minHeight: "60px",
  },
  snackbarContent: (props: StyleProps): any => ({
    margin: "30px 30px",
    backgroundColor: props.isPurple ? "#836CF2" : theme.palette.primary.light,
    borderRadius: "8px",
  }),
  message: (props: StyleProps): any => ({
    color: props.isPurple ? "white" : "rgba(129,136,149,1)",
    fontSize: "14px",
    display: "flex",
    marginLeft: "5px",
    alignItems: "center",
  }),
  icon: {
    width: "20px",
    height: "20px",
    "& svg": {
      height: "20px",
      width: "20px",
    },
    "&> div": {
      height: "20px",
      display: "flex",
    },
  },
  messageText: {
    marginTop: "2px",
    marginLeft: "14px",
  },
  closeIcon: (props: StyleProps): any => ({
    width: "12px",
    height: "12px",
    margin: "20px 10px",

    "&:hover": {
      cursor: "pointer",
    },
    "& svg": {
      height: "12px",
      width: "12px",
    },
    "& g": {
      fill: props.isPurple ? "white" : "rgba(129,136,149,1)",
    },
    "&> div": {
      height: "12px",
      display: "flex",
    },
  }),
}));

const autoHideTime = 3000;

const CustomSnackbar: React.FC<{
  message: string;
  date: Date;
  open: boolean;
  isClosable: boolean;
  icon?: string;
  isPurple?: boolean;
}> = ({ message, date, open, icon, isClosable, isPurple }): JSX.Element => {
  const classes = useStyles({ isPurple });
  const [isOpen, setOpen] = useState(open);

  useEffect(() => setOpen(open), [open, date]);

  const handleClose = (): void => {
    setOpen(false);
  };
  console.log(icon);
  return (
    <Snackbar
      open={isOpen}
      onClose={(event, reason): void => {
        reason === "clickaway" ? setOpen((p) => p) : setOpen(false);
      }}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={isClosable ? autoHideTime : null}
      disableWindowBlurListener
    >
      <SnackbarContent
        className={classes.snackbarContent}
        message={
          <span className={classes.message}>
            {icon && <ReactSVG src={icon} className={classes.icon} />}
            <div className={classes.messageText}>{message}</div>
          </span>
        }
        action={
          isClosable
            ? [
                <ReactSVG
                  key="close"
                  src={closeIcon}
                  className={classes.closeIcon}
                  onClick={handleClose}
                />,
              ]
            : []
        }
      />
    </Snackbar>
  );
};

CustomSnackbar.defaultProps = {
  message: "done",
};

CustomSnackbar.propTypes = {
  message: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  open: PropTypes.bool.isRequired,
  icon: PropTypes.string,
  isClosable: PropTypes.bool.isRequired,
  isPurple: PropTypes.bool,
};

export default CustomSnackbar;
