import {
  CHANGE_LOCAL_VIDEO_VISIBILITY,
  CHANGE_REMOTE_VIDEO_VISIBILITY,
  CLEAR_STORE,
  CHANGE_LOCAL_AUDIO_VOLUME,
  CHANGE_CALL_JOIN,
  ADD_MESSAGE,
  CLEAR_MESSAGES,
  SET_CREATOR,
  CHANGE_ANOTHER_JOIN,
  INITIALIZE_SPEACH_RECOGNITION,
  CHANGE_CONNECTION_STATE,
  CHANGE_ROOM_NAME,
} from "../constants/types";

import {
  ChangeLocalVideoVisibilityAction,
  ChangeRemoteVideoVisibilityAction,
  ClearStoreAction,
  ChangeLocalAudioVolumeAction,
  AddMessageAction,
  ClearMessagesAction,
  ChangeCallJoinAction,
  SetCreatorAction,
  ChangeAnotherJoinAction,
  InitializeSpeechRecognition,
  ChangeConnectionState,
  ChangeRoomName,
} from "../constants/types";

export function changeLocalVideoVisibility(
  payload: ChangeLocalVideoVisibilityAction["payload"]
): ChangeLocalVideoVisibilityAction {
  return {
    type: CHANGE_LOCAL_VIDEO_VISIBILITY,
    payload,
  };
}

export function changeRemoteVideoVisibility(
  payload: ChangeRemoteVideoVisibilityAction["payload"]
): ChangeRemoteVideoVisibilityAction {
  return {
    type: CHANGE_REMOTE_VIDEO_VISIBILITY,
    payload,
  };
}

export function changeLocalAudioVolume(
  payload: ChangeLocalAudioVolumeAction["payload"]
): ChangeLocalAudioVolumeAction {
  return {
    type: CHANGE_LOCAL_AUDIO_VOLUME,
    payload,
  };
}

export function clearStore(): ClearStoreAction {
  return {
    type: CLEAR_STORE,
  };
}

export function changeCallJoin(
  payload: ChangeCallJoinAction["payload"]
): ChangeCallJoinAction {
  return {
    type: CHANGE_CALL_JOIN,
    payload,
  };
}

export function addMessage(
  payload: AddMessageAction["payload"]
): AddMessageAction {
  return {
    type: ADD_MESSAGE,
    payload,
  };
}

export function clearMessages(): ClearMessagesAction {
  return {
    type: CLEAR_MESSAGES,
  };
}

export function setCreator(
  payload: SetCreatorAction["payload"]
): SetCreatorAction {
  return {
    type: SET_CREATOR,
    payload,
  };
}

export function changeAnotherJoin(
  payload: ChangeAnotherJoinAction["payload"]
): ChangeAnotherJoinAction {
  return {
    type: CHANGE_ANOTHER_JOIN,
    payload,
  };
}

export function initializeSpeechRecognition(): InitializeSpeechRecognition {
  return {
    type: INITIALIZE_SPEACH_RECOGNITION,
  };
}

export function changeConnectionState(
  payload: ChangeConnectionState["payload"]
): ChangeConnectionState {
  return {
    type: CHANGE_CONNECTION_STATE,
    payload,
  };
}

export function changeRoomName(
  payload: ChangeRoomName["payload"]
): ChangeRoomName {
  return {
    type: CHANGE_ROOM_NAME,
    payload,
  };
}
