import { AppState } from "store";
export interface CallPageProps {
  isAnotherJoined: boolean;
  isCallJoined: boolean;
  snackbar: Snackbar;
  startedTalking: boolean;
}
export interface SnackbarInterface {
  date: Date;
  open: boolean;
  message?: string;
  isClosable?: boolean;
  icon?: string | undefined;
  isPurple?: boolean;
}

export class Snackbar implements SnackbarInterface {
  constructor(
    open: boolean,
    message?: string,
    icon?: string | undefined,
    isClosable?: boolean,
    isPurple?: boolean
  ) {
    this.date = new Date();
    this.open = open;
    this.message = message ? message : "";
    this.isClosable = isClosable ? isClosable : true;
    this.icon = icon ? icon : "";
    this.isPurple = isPurple ? isPurple : false;
  }
  date: Date;
  message: string;
  open: boolean;
  icon?: string | undefined;
  isClosable: boolean;
  isPurple: boolean;
}

export interface StateProps {
  isCreator: boolean;
  isCallJoined: boolean;
  isAnotherJoined: boolean;
  startedTalking: boolean;
  roomName: string;
}

export const mapStateToProps = (state: AppState): StateProps => {
  const startedTalking =
    state.chatState.captions.toArray().length > 0 ? true : false;
  return {
    isCreator: state.chatState.isCreator,
    isCallJoined: state.chatState.isCallJoined,
    isAnotherJoined: state.chatState.isAnotherJoined,
    startedTalking: startedTalking,
    roomName: state.chatState.roomName,
  };
};
