import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import ReactSVG from "react-svg";
import useReactRouter from "use-react-router";
import { isBrowser } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { AppState } from "store";

import {
  Grid,
  IconButton,
  Fade,
  Typography,
  useMediaQuery,
  Theme,
} from "@material-ui/core";

import CaptionContainer from "../caption/CaptionContainer";
import PermissionDialog from "../permission-dialog/PermissionDialog";
import PermissionService from "services/permission-service/permissionService";
import { useStyles } from "./CallControlStyles";
import { ShareDialog } from "components/common/ShareDialog";
import CallEndDialog from "components/common/CallEndDialog";
import { TipsCarousel } from "../tips-carousel/TipsCarousel";
import {
  CONNECTING_OVERLAY_TIME,
  CONNECTING_OVERLAY_TRANSITION,
} from "../../../config/settings";

import {
  clearStore,
  changeLocalVideoVisibility,
  changeLocalAudioVolume,
  clearMessages,
} from "store/chat/actions";
import { changeMode } from "store/layout/actions";

import SettingsPanel from "components/common/SettingsPanel/SettingsPanel";
import { useSettings } from "components/common/SettingsPanel/useSettings";

import shareIcon from "img/Invite.svg";
import phoneIcon from "img/Call-without-arrow.svg";
import micIconOn from "img/Mic-on.svg";
import micIconOff from "img/Mic-off.svg";
import camIconOn from "img/Cam-on.svg";
import camIconOff from "img/Cam-off.svg";
import backArrow from "img/Back-arrow.svg";
import joinIcon from "img/Join-room.svg";

import settingsIcon from "img/Settings.svg";

import callArrow from "img/Call-arrow.svg";
import HalfScreenOn from "img/Half-screen-on.svg";
import FullScreen from "img/Half-screen-off.svg";
import FullTextIcon from "img/Full-text-icon.svg";
import StreamInterface from "../../../services/agora/stream";
import RtmInterface from "../../../services/agora/rtm";

import { ConnectionState } from "~/../store/chat/constants/types";
import SpeechRecognitionService from "services/speech-recognition/speechRecognitionService";
import { Modes } from "~/../store/layout/constants/types";

interface StateProps {
  isLocalVideo: boolean;
  isLocalAudio: boolean;
  speechRecognitionService: SpeechRecognitionService | undefined;
  isCreator: boolean;
  isTips: boolean;
  connectionState: string;
  mode: Modes;
  captionsListHeight: number;
  isAnotherJoined: boolean;
  isCallJoined: boolean;
  roomName: string;
}

interface State {
  shareDialog: {
    isOpen: boolean;
    date: Date;
  };
  isConnectingOverlay: boolean;
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    isLocalVideo: state.chatState.isLocalVideo,
    isLocalAudio: state.chatState.isLocalAudio,
    speechRecognitionService: state.chatState.speechRecognitionService,
    isCreator: state.chatState.isCreator,
    isCallJoined: state.chatState.isCallJoined,
    connectionState: state.chatState.connectionState,
    isAnotherJoined: state.chatState.isAnotherJoined,
    roomName: state.chatState.roomName,
    mode: state.layoutState.mode,
    isTips: state.layoutState.isTips,
    captionsListHeight: state.layoutState.captionsListHeight,
  };
};

const CallControl: React.FC = (): JSX.Element => {
  const [permissionService, setPermissionService] = useState<
    PermissionService
  >();
  const [shareDialog, setShareDialog] = useState({
    isOpen: false,
    date: new Date(),
  });
  const [isConnectingOverlay, setIsConnectingOverlay] = useState(false);
  const [isPeerConnectingOverlay, setIsPeerConnectingOverlay] = useState(false);
  const [isExitOverlay, setIsExitOverlay] = useState(false);
  const [isCallEndDialog, setCallEndDialog] = useState(false);

  const {
    isCreator,
    isLocalAudio,
    isLocalVideo,
    isTips,
    isCallJoined,
    speechRecognitionService,
    connectionState,
    mode,
    captionsListHeight,
    isAnotherJoined,
    roomName,
  } = useSelector(mapStateToProps, shallowEqual);
  const dispatch = useDispatch();
  const { history } = useReactRouter();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const c = useStyles({
    isLocalAudio,
    isLocalVideo,
    isHalfMode: mode === Modes.half,
    isDesktop: !isMobile,
    captionsListHeight,
    isCallJoined,
  });
  const { t } = useTranslation();
  const { isSettings, handleSettings } = useSettings();

  useEffect(() => {
    setPermissionService(new PermissionService());
  }, []);

  useEffect(() => {
    dispatch(changeMode({ mode: Modes.full }));
  }, [dispatch]);

  useEffect(() => {
    if (isLocalAudio && isBrowser && speechRecognitionService !== undefined)
      speechRecognitionService.start();
    return (): void => {
      speechRecognitionService !== undefined && speechRecognitionService.stop();
    };
  }, [dispatch, isLocalAudio, permissionService, speechRecognitionService]);

  useEffect(() => {
    if (isAnotherJoined && isCallJoined && !isConnectingOverlay)
      setIsPeerConnectingOverlay(true);
    setTimeout((): void => {
      setIsPeerConnectingOverlay(false);
    }, CONNECTING_OVERLAY_TIME * 2000);
  }, [isAnotherJoined, isCallJoined, isConnectingOverlay]);

  const handleLocalVideoVisibility = (): void => {
    if (isLocalVideo) {
      StreamInterface.localStream.muteVideo();
      StreamInterface.stopStream();
      dispatch(changeLocalVideoVisibility({ isLocalVideo: false }));
    } else {
      StreamInterface.localStream.unmuteVideo();
      dispatch(changeLocalVideoVisibility({ isLocalVideo: true }));
      setTimeout(function () {
        StreamInterface.playStream();
      }, 500);
    }
  };

  const handleLocalAudioVolume = (): void => {
    if (isLocalAudio) {
      StreamInterface.localStream.muteAudio();
      dispatch(changeLocalAudioVolume({ isLocalAudio: false }));
    } else {
      StreamInterface.localStream.unmuteAudio();
      dispatch(changeLocalAudioVolume({ isLocalAudio: true }));
    }
  };

  const handleCallShare = (): void => {
    setShareDialog({ isOpen: true, date: new Date() });
  };

  const handleCallJoin = (): void => {
    if (!isCallJoined) {
      StreamInterface.setChannel(roomName);
      StreamInterface.initClient();
      StreamInterface.initLocalStream();
      RtmInterface.loginRTM();
      RtmInterface.createChannel(roomName);
      StreamInterface.joinChannel();
      dispatch(clearMessages());
    }
    setIsConnectingOverlay(true);
    setTimeout((): void => {
      setIsConnectingOverlay(false);
    }, CONNECTING_OVERLAY_TIME * 1000);
  };

  const handleCallEndDialog = (): void => {
    setCallEndDialog(!isCallEndDialog);
  };

  const handleCallEnd = (): void => {
    handleCallEndDialog();
    setIsExitOverlay(true);
    setTimeout(function () {
      StreamInterface.client.leave();
      history.push("/");
      dispatch(clearStore());
    }, CONNECTING_OVERLAY_TIME * 500);
  };

  const handleModeChange = (mode: Modes): void => {
    dispatch(changeMode({ mode }));
  };

  const handleNextMode = (): void => {
    if (mode === Modes.full) dispatch(changeMode({ mode: Modes.text }));
    if (mode === Modes.text) dispatch(changeMode({ mode: Modes.half }));
    if (mode === Modes.half) dispatch(changeMode({ mode: Modes.full }));
  };

  const EndCallButton = (
    <IconButton className={c.endButton} onClick={handleCallEndDialog}>
      <ReactSVG src={phoneIcon} className={c.endIcon} />
    </IconButton>
  );

  const ShareButton = (
    <IconButton className={c.shareButton} onClick={handleCallShare}>
      <ReactSVG src={shareIcon} className={c.shareIcon} />
      <Typography variant={isMobile ? "body1" : "h6"} className={c.shareText}>
        {t("CallControl.invite")}
      </Typography>
    </IconButton>
  );

  const JoinCallButton = (
    <IconButton className={c.joinButton} onClick={handleCallJoin}>
      <ReactSVG src={joinIcon} className={c.joinIcon} />
      <Typography variant={isMobile ? "body1" : "h6"} className={c.joinText}>
        {t("CallControl.join")}
      </Typography>
    </IconButton>
  );

  const AudioButton = (
    <IconButton className={c.controlButton} onClick={handleLocalAudioVolume}>
      {isLocalAudio && <ReactSVG src={micIconOn} className={c.audioIcon} />}
      {!isLocalAudio && <ReactSVG src={micIconOff} className={c.audioIcon} />}
    </IconButton>
  );

  const VideoButton = (
    <IconButton
      className={c.controlButton}
      onClick={handleLocalVideoVisibility}
    >
      {isLocalVideo && <ReactSVG src={camIconOn} className={c.videoIcon} />}
      {!isLocalVideo && <ReactSVG src={camIconOff} className={c.videoIcon} />}
    </IconButton>
  );

  const changeModeButton = (
    <div className={c.modesControl}>
      {isMobile && (
        <IconButton
          onClick={(): void => handleNextMode()}
          className={c.modeButtonHalfScreen}
        >
          <ReactSVG
            src={
              mode === Modes.full
                ? FullTextIcon
                : mode === Modes.half
                ? HalfScreenOn
                : FullScreen
            }
            className={c.modeScreenIcon}
          />
        </IconButton>
      )}
      {!isMobile && (
        <>
          <IconButton
            onClick={(): void => handleModeChange(Modes.full)}
            className={`${c.modeButton} ${mode === Modes.full && "active"}`}
          >
            <ReactSVG src={HalfScreenOn} className={c.modeScreenIcon} />
          </IconButton>
          <IconButton
            onClick={(): void => handleModeChange(Modes.half)}
            className={`${c.modeButton} ${mode === Modes.half && "active"}`}
          >
            <ReactSVG src={FullScreen} className={c.modeScreenIcon} />
          </IconButton>
          <IconButton
            onClick={(): void => handleModeChange(Modes.text)}
            className={`${c.modeButton} ${mode === Modes.text && "active"}`}
          >
            <ReactSVG src={FullTextIcon} className={c.modeScreenIcon} />
          </IconButton>
        </>
      )}
    </div>
  );

  const ExitOverlay = (
    <Fade
      enter={true}
      exit={false}
      in={isExitOverlay}
      timeout={{
        enter: CONNECTING_OVERLAY_TIME * 5000,
      }}
      mountOnEnter
      unmountOnExit
    >
      <div className={c.exitOverlay}></div>
    </Fade>
  );

  const ConnectingOverlay = (
    <Fade
      enter={true}
      exit={true}
      in={isConnectingOverlay}
      timeout={{
        enter: CONNECTING_OVERLAY_TIME * 1000,
        exit: CONNECTING_OVERLAY_TRANSITION * 1000,
      }}
      mountOnEnter
      unmountOnExit
    >
      <div className={c.connectingOverlay}>
        <div className={c.connectingIconDiv}>
          <div>
            <div>
              <ReactSVG src={callArrow} />
            </div>
          </div>
        </div>
        <div className={c.ConnectingMessage}>
          <Typography variant="h5" className={c.connectingOverlayText}>
            {t("CallControl.joiningRoom")}
          </Typography>
        </div>
      </div>
    </Fade>
  );

  const PeerConnectingOverlay = (
    <Fade
      enter={false}
      exit={true}
      in={isPeerConnectingOverlay}
      timeout={{
        enter: CONNECTING_OVERLAY_TIME * 1000,
        exit: CONNECTING_OVERLAY_TRANSITION * 1000,
      }}
      mountOnEnter
      unmountOnExit
    >
      <div className={c.connectingOverlay}>
        <div className={c.connectingIconDiv}>
          <div>
            <div>
              <ReactSVG src={callArrow} />
            </div>
          </div>
        </div>
        <div className={c.ConnectingMessage}>
          <Typography variant="h5" className={c.connectingOverlayText}>
            {t("CallControl.someone")}
          </Typography>
          <Typography variant="h5" className={c.connectingOverlayTextAlt}>
            {t("CallControl.someouneJoiningRoom")}
          </Typography>
        </div>
      </div>
    </Fade>
  );

  const BackMobile = (
    <div className={c.settingsBackArrow}>
      {!isAnotherJoined && isCreator && (
        <IconButton
          onClick={handleCallEndDialog}
          className={c.backArrowButtonRoot}
        >
          <ReactSVG src={backArrow} className={c.backArrowIcon} />
        </IconButton>
      )}
      <IconButton onClick={handleSettings} className={c.settingsButton}>
        <ReactSVG src={settingsIcon} className={c.settingsIcon} />
      </IconButton>
    </div>
  );

  const BackDesktop = (
    <div className={c.settingsBackArrow}>
      {!isAnotherJoined && isCreator && (
        <div className={c.settingsItem} onClick={handleCallEndDialog}>
          <IconButton disabled className={c.settingsButton}>
            <ReactSVG src={backArrow} className={c.backArrowIcon} />
          </IconButton>
          <Typography variant="h6">{t("CallControl.back")}</Typography>
        </div>
      )}
      <div className={c.settingsItem} onClick={handleSettings}>
        <IconButton disabled className={c.settingsButton}>
          <ReactSVG src={settingsIcon} className={c.settingsIcon} />
        </IconButton>
        <Typography variant="h6">{t("CallControl.settings")}</Typography>
      </div>
    </div>
  );

  return (
    <div className={c.callControl}>
      {ConnectingOverlay}
      {PeerConnectingOverlay}
      {ExitOverlay}
      {isAnotherJoined && changeModeButton}
      <SettingsPanel isOpen={isSettings} handleSettings={handleSettings} />
      <PermissionDialog />
      <ShareDialog open={shareDialog.isOpen} date={shareDialog.date} />
      <CallEndDialog
        open={isCallEndDialog}
        handleCallEnd={handleCallEnd}
        handleCallEndDialog={handleCallEndDialog}
      />
      {isMobile && BackMobile}
      {!isMobile && BackDesktop}
      <Grid container direction="column" alignItems="center" spacing={2}>
        <Grid item className={c.captionItem} xs={10} md={6} lg={6}>
          <CaptionContainer />
          {isTips && mode === Modes.half && !isMobile && <TipsCarousel />}
        </Grid>
        {((isTips && mode !== Modes.half) || (isTips && isMobile)) && (
          <TipsCarousel />
        )}
        <Grid className={c.contollersContainer} item>
          <Grid container alignItems="center" justify="center" spacing={3}>
            <Grid item className={c.controlItem}>
              {AudioButton}
            </Grid>
            <Grid item className={c.controlItem}>
              {isCreator && !isAnotherJoined && ShareButton}
              {(connectionState === ConnectionState.connected ||
                connectionState === ConnectionState.connecting ||
                isAnotherJoined) &&
                EndCallButton}
              {!(
                connectionState === ConnectionState.connected ||
                connectionState === ConnectionState.connecting ||
                isAnotherJoined
              ) && JoinCallButton}
            </Grid>
            <Grid item className={c.controlItem}>
              {VideoButton}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default CallControl;
