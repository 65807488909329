import { Theme, makeStyles } from "@material-ui/core";

interface StyleProps {
  isLocalVideo: boolean;
  isLocalAudio: boolean;
  isHalfMode: boolean;
  isDesktop: boolean;
  captionsListHeight: number;
  isCallJoined: boolean;
}

export const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  callControl: {
    zIndex: 10,
    position: "absolute",
    left: 0,
    right: 0,
    bottom: "5vh",
    textAlign: "center",
  },
  contollersContainer: (props: StyleProps) => ({
    width: props.isHalfMode && props.isDesktop ? "auto" : "100%",
    marginRight: "auto",
    marginLeft: props.isHalfMode && props.isDesktop ? "60px" : "0",
    "&> div": {
      display: "flex",
      justifyContent: "center",
    },
  }),
  captionItem: (props: StyleProps) => ({
    width: "100%",
    maxHeight:
      props.isDesktop && props.isHalfMode
        ? "95vh"
        : `${props.captionsListHeight}px`,
    display: "flex",
    flexDirection: "column",
    maxWidth: props.isDesktop && props.isHalfMode ? "480px" : "1000px",
    margin:
      props.isDesktop && props.isHalfMode ? "0px 0px 30px auto" : "0px auto",
    padding: "8px 8px 8px 0",
    transform:
      props.isDesktop && props.isHalfMode ? "translateY(164px)" : "none",
  }),
  controlButton: (props: StyleProps) => ({
    zIndex: 99,
    backgroundColor:
      theme.palette.type === "light" ? "#FFFFFF" : "rgba(255,255,255,0.1)",
    height: props.isDesktop ? "84px" : "50px",
    width: props.isDesktop ? "84px" : "50px",
    border: "1px solid rgba(228,228,228,1)",
    borderColor: theme.palette.type === "light" ? "#E4E4E4" : "transparent",
    borderRadius: "50%",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgb(228, 228, 228)",
    },
  }),
  modesControl: (props: StyleProps) => ({
    display: "flex",
    position: "fixed",
    bottom: !props.isDesktop ? "calc(100vh - 110px)" : "45px",
    right: !props.isDesktop
      ? "calc(50vw - 30px) "
      : props.isDesktop && props.isHalfMode
      ? " 500px"
      : "45px",
  }),
  modeButton: (props: StyleProps) => ({
    margin: "15px",
    backgroundColor:
      theme.palette.type === "light" ? "#FFFFFF" : "rgba(255,255,255,0.1)",
    height: props.isDesktop ? "84px" : "60px",
    width: props.isDesktop ? "84px" : "60px",
    border: "1px solid rgba(228,228,228,1)",
    borderColor: theme.palette.type === "light" ? "#E4E4E4" : "transparent",
    borderRadius: "50%",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgb(228, 228, 228)",
    },
    "&.active": {
      background: "#836CF2",
      border: "none",
      "& g": {
        fill: "white",
      },
    },
  }),
  audioIcon: (props: StyleProps) => ({
    "& svg": {
      height: props.isDesktop ? "36px" : "20px",
      width: props.isDesktop ? "24px" : "20px",
    },
    "& g": {
      fill: props.isLocalAudio ? "rgba(11,8,27,1)" : "rgba(129,136,149,1)",
    },
    "&> div": {
      height: props.isDesktop ? "36px" : "20px",
      display: "flex",
    },
  }),
  videoIcon: (props: StyleProps) => ({
    "& svg": {
      height: props.isDesktop ? "20px" : "20px",
      width: props.isDesktop ? "34px" : "20px",
    },
    "& g": {
      fill: props.isLocalVideo ? "rgba(11,8,27,1)" : "rgba(129,136,149,1)",
    },
    "&> div": {
      height: props.isDesktop ? "20px" : "20px",
      display: "flex",
    },
  }),
  modeScreenIcon: (props: StyleProps) => ({
    "& svg": {
      height: props.isDesktop ? "30px" : "20px",
      width: props.isDesktop ? "30px" : "20px",
    },
    "& g": {
      fill: "rgba(11,8,27,1)",
    },
    "&> div": {
      height: props.isDesktop ? "30px" : "20px",
      display: "flex",
    },
  }),
  endButton: (props: StyleProps) => ({
    zIndex: 99,
    background: "#FF5E52",
    height: props.isDesktop ? "100px" : "75px",
    width: props.isDesktop ? "100px" : "75px",
    boxShadow: "0px 8px 12px rgba(255, 94, 82, 0.25)",
    "&:hover": {
      boxShadow: "0px 8px 12px rgba(232, 232, 232, 0.25)",
    },
  }),
  endIcon: (props: StyleProps) => ({
    transform: "rotate(90deg)",
    "& svg": {
      height: props.isDesktop ? "32px" : "24px",
      width: props.isDesktop ? "32px" : "24px",
    },
    "&> div": {
      height: props.isDesktop ? "32px" : "24px",
      display: "flex",
    },
    "& g": {
      fill: theme.palette.primary.light,
    },
  }),
  shareButton: (props: StyleProps) => ({
    background: "linear-gradient(135deg, #FF71A3 0%, #FFA0A7 100%)",
    height: props.isDesktop ? "84px" : "50px",
    width: props.isDesktop ? "193px" : "120px",
    borderRadius: props.isDesktop ? "50px" : "37.5px",
    boxShadow: "0px 8px 12px rgba(255, 146, 166, 0.25)",
    margin: props.isDesktop ? "15px" : "0px",
  }),
  shareIcon: (props: StyleProps) => ({
    "& svg": {
      height: props.isDesktop ? "36px" : "20px",
      width: props.isDesktop ? "38px" : "20px",
    },
    "&> div": {
      height: props.isDesktop ? "36px" : "20px",
      display: "flex",
    },
  }),
  shareText: {
    color: theme.palette.primary.light,
    marginLeft: "10px",
    marginTop: "2px",
    fointSize: "24px",
  },
  joinButton: (props: StyleProps) => ({
    background: "rgba(131,108,242,1)",
    height: props.isDesktop ? "84px" : "75px",
    width: props.isDesktop ? "241px" : "170px",
    borderRadius: props.isDesktop ? "50px" : "37.5px",
    margin: "15px",
  }),
  joinIcon: (props: StyleProps) => ({
    "& svg": {
      transform: "translateY(-2px)",
      height: props.isDesktop ? "36px" : "20px",
      width: props.isDesktop ? "34px" : "20px",
    },
    "&> div": {
      height: props.isDesktop ? "25px" : "20px",
      display: "flex",
    },
  }),
  joinText: {
    color: theme.palette.primary.light,
    marginLeft: "8px",
    marginTop: "2px",
  },
  connectingOverlay: {
    zIndex: 1,
    backgroundColor: theme.palette.type === "light" ? "#fff" : "#0B081B",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: "fixed",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  exitOverlay: {
    zIndex: 999,
    backgroundColor: "rgba(11, 8, 27, .4)",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: "fixed",
  },
  connectingOverlayText: {
    color: "#836CF2",
    lineHeight: "48px",
  },
  connectingOverlayTextAlt: {
    color: theme.palette.type === "light" ? "#836CF2" : "#fff",
    lineHeight: "48px",
  },
  ConnectingMessage: {
    transform: "translateY(-125%)",
  },
  connectingIconDiv: (props: StyleProps) => ({
    marginTop: props.isDesktop ? "10vh" : "5vh",
    display: "flex",
    height: "460px",
    width: "460px",
    border:
      theme.palette.type === "light"
        ? "1px solid #fff"
        : "1px solid rgba(130,108,242, .2)",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    "& div": {
      display: "flex",
      height: "326px",
      width: "326px",
      border:
        theme.palette.type === "light"
          ? "1px solid #fff"
          : "1px solid rgba(130,108,242, .2)",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
      "& div": {
        display: "flex",
        height: "210px",
        width: "210px",
        border:
          theme.palette.type === "light"
            ? "1px solid #fff"
            : "1px solid rgba(130,108,242, .2)",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        "& svg": {
          height: "76px",
          width: "76px",
        },
        "& g": { fill: theme.palette.secondary.light },
      },
    },
  }),
  settingsBackArrow: (props: StyleProps) => ({
    zIndex: 99,
    position: "fixed",
    top: "50px",
    left: props.isDesktop ? "60px" : "30px",
    display: "flex",
  }),
  backArrowIcon: {
    "&> div": {
      height: "15px",
      width: "15px",
      display: "flex",
    },
    "& svg": {
      height: "15px",
      width: "15px",
    },
    "& g": {
      fill:
        theme.palette.type === "light"
          ? "rgba(129,136,149,1)"
          : theme.palette.primary.dark,
    },
  },
  backArrowButtonRoot: {
    width: "60px",
    height: "60px",
  },
  settingsButton: (props: StyleProps) => ({
    // border:
    //   props.isRemoteVideo || props.isDesktop
    //     ? "unset"
    //     : "1px solid rgba(235,236,238,1)",
    display: "inline-flex",
    height: props.isDesktop ? "fit-content" : "60px",
    width: props.isDesktop ? "fit-content" : "60px",
    borderRadius: "30px",
    justifyContent: "center",
    alignItems: "center",
    padding: "unset",
    marginRight: props.isDesktop ? "10px" : 0,
  }),
  settingsIcon: (props: StyleProps) => ({
    boxShadow:
      !props.isCallJoined && !props.isDesktop
        ? "0 0 0 20px rgba(255,255,255,0.2), inset 0 0 0 20px rgba(255,255,255,0.2)"
        : "unset",
    borderRadius: "30px",
    "& svg": {
      height: "24px",
      width: "24px",
    },
    "& g": {
      fill: !props.isCallJoined ? "rgba(129,136,149,1)" : "#232132",
    },
    "&> div": {
      height: "24px",
      display: "flex",
    },
  }),
  settingsItem: (props: StyleProps) => ({
    border: "1px solid #EBECEE",
    borderRadius: "30px",
    height: "60px",
    width: "160px",
    background: "#ffff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "20px",
    color: !props.isCallJoined ? "rgba(129,136,149,1)" : "#232132",
    marginRight: "10px",
    backgroundColor: theme.palette.type === "light" ? "#fff" : "#232132",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgb(228, 228, 228)",
    },
  }),
  captionControlsContainer: (props: StyleProps) => ({
    width: props.isDesktop && props.isHalfMode ? "calc(100% - 480px)" : "100%",
    marginRight: "auto",
    "&>div": {
      height: "cal(10vh + 84px)",
      minHeight: "168px",
    },
  }),
  controlItem: {
    margin: "3px",
  },
}));
