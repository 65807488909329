import {
  ToogleDarkModeAction,
  TOOGLE_DARK_MODE,
  ChangeFontSizeAction,
  CHANGE_FONT_SIZE,
  ChangeFontFamilyAction,
  CHANGE_FONT_FAMILY,
  ChangeFontColorAction,
  CHANGE_FONT_COLOR,
  ChangeOtherBubbleColorAction,
  CHANGE_OTHER_BUBBLE_COLOR,
} from "../constants/types";

export function toogleDarkMode(
  payload: ToogleDarkModeAction["payload"]
): ToogleDarkModeAction {
  return {
    type: TOOGLE_DARK_MODE,
    payload,
  };
}

export function changeFontSize(
  payload: ChangeFontSizeAction["payload"]
): ChangeFontSizeAction {
  return {
    type: CHANGE_FONT_SIZE,
    payload,
  };
}

export function changeFontFamily(
  payload: ChangeFontFamilyAction["payload"]
): ChangeFontFamilyAction {
  return {
    type: CHANGE_FONT_FAMILY,
    payload,
  };
}

export function changeFontColor(
  payload: ChangeFontColorAction["payload"]
): ChangeFontColorAction {
  return {
    type: CHANGE_FONT_COLOR,
    payload,
  };
}

export function ChangeOtherBubbleColor(
  payload: ChangeOtherBubbleColorAction["payload"]
): ChangeOtherBubbleColorAction {
  return {
    type: CHANGE_OTHER_BUBBLE_COLOR,
    payload,
  };
}
