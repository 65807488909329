import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import ReactSVG from "react-svg";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { Typography, Theme, Button, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { changeOnboarding } from "store/layout/actions";
import { Dot } from "./Dot";
import { OnboardCarouselHeader } from "./OnboardCarouselHeader";

import walkthrough1 from "img/Walkthrough-1.svg";
import walkthrough2 from "img/Walkthrough-2.svg";
import walkthrough3 from "img/Walkthrough-3.svg";
import walkthrough4 from "img/Walkthrough-4.svg";
import walkthrough5 from "img/Walkthrough-5.svg";

import closeIcon from "img/Close.svg";

const slideNumberFromZero = 4;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "absolute",
    height: "100%",
    width: "100%",
    zIndex: 10,
    backgroundColor: "rgba(11,8,27,0.9)",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "rgba(255,255,255,1)",
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  containerMobile: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "315px",
    marginLeft: "auto",
    marginRight: "auto",
    height: "100%",
    justifyContent: "space-evenly",
    backgroundColor: "rgba(255,255,255,1)",
  },
  continueMobile: {
    backgroundColor: theme.palette.secondary.light,
    height: "60px",
    textTransform: "none",
    display: "block",
    width: "100%",
    "&:hover": {
      backgroundColor: theme.palette.secondary.light + " !important",
    },
  },
  continueMobileText: {
    color: theme.palette.primary.light,
  },
  skipMobile: {
    backgroundColor: "transparent",
    textTransform: "none",
    display: "block",
    marginTop: "10px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  skipMobileText: {
    color: theme.palette.secondary.light,
  },
  containerDesktop: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    backgroundColor: "rgba(255,255,255,1)",
    height: "800px",
    maxHeight: "90%",
    width: "800px",
    maxWidth: "90%",
    alignItems: "center",
    borderRadius: "8px",
    position: "relative",
  },
  buttonsDesktop: {},
  continueDesktop: {
    backgroundColor: theme.palette.secondary.light,
    height: "60px",
    textTransform: "none",
    display: "inline-flex",
    width: "180px",
    "&:hover": {
      backgroundColor: theme.palette.secondary.light + " !important",
    },
  },
  continueDesktopText: {
    color: theme.palette.primary.light,
  },
  skipDesktop: {
    backgroundColor: "transparent",
    textTransform: "none",
    display: "inline-flex",
    border: "1px solid rgba(131,108,242,0.4)",
    borderRadius: "8px",
    height: "60px",
    width: "180px",
    marginLeft: "20px",
  },
  skipDesktopText: {
    color: theme.palette.secondary.light,
  },
  slider: {
    textAlign: "center",
  },
  slidersContainerDesktop: {
    width: "100%",
  },
  dots: {
    textAlign: "center",
    marginTop: "40px",
  },
  slide: {
    height: "100%",
    display: "flex",
    "& img": {
      maxWidth: "80vw",
      margin: "auto",
      maxHeight: "40vh",
    },
  },
  close: {
    position: "absolute",
    top: "20px",
    right: "20px",
    "& svg": {
      height: "15px",
      width: "15px",
    },
    "& g": {
      fill: "rgba(129,136,149,1)",
    },
    "&> div": {
      height: "15px",
      display: "flex",
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

export const OnboardCarousel: React.FC<{}> = (): JSX.Element => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const classes = useStyles();

  const handleNextSlide = (index: number = currentSlide): void => {
    const oldIndex = currentSlide;
    if (index > slideNumberFromZero) {
      dispatch(changeOnboarding({ isOnboarding: false }));
    } else {
      setCurrentSlide(index);
      setTimeout((): void => {
        if (index < oldIndex) {
          setCurrentSlide(oldIndex);
        }
      }, 1);
    }
  };

  const handleSkip = (): void => {
    dispatch(changeOnboarding({ isOnboarding: false }));
  };

  const Dots = (
    <div>
      <Dot dotNumber={0} sliderNumber={currentSlide} />
      <Dot dotNumber={1} sliderNumber={currentSlide} />
      <Dot dotNumber={2} sliderNumber={currentSlide} />
      <Dot dotNumber={3} sliderNumber={currentSlide} />
      <Dot dotNumber={4} sliderNumber={currentSlide} />
    </div>
  );

  const GuideViews = (
    <SwipeableViews
      enableMouseEvents
      slideClassName={classes.slider}
      index={currentSlide}
      onChangeIndex={handleNextSlide}
      resistance={true}
      onSwitching={(index): void => {
        if (index > slideNumberFromZero)
          dispatch(changeOnboarding({ isOnboarding: false }));
      }}
    >
      <div className={classes.slide}>
        <img src={walkthrough1} alt="" />
      </div>
      <div className={classes.slide}>
        <img src={walkthrough2} alt="" />
      </div>
      <div className={classes.slide}>
        <img src={walkthrough3} alt="" />
      </div>
      <div className={classes.slide}>
        <img src={walkthrough4} alt="" />
      </div>
      <div className={classes.slide}>
        <img src={walkthrough5} alt="" />
      </div>
    </SwipeableViews>
  );

  const MobileOnboard = (
    <div className={classes.containerMobile}>
      <div>
        <OnboardCarouselHeader isMobile={true} slideNumber={currentSlide} />
      </div>
      <div>
        {Dots}
        {GuideViews}
      </div>
      <div>
        <Button
          className={classes.continueMobile}
          onClick={(): void => {
            handleNextSlide(currentSlide + 1);
          }}
        >
          <Typography variant="body1" className={classes.continueMobileText}>
            {t("OnboardCarousel.continue")}
          </Typography>
        </Button>
        <Button className={classes.skipMobile} onClick={handleSkip}>
          <Typography variant="body1" className={classes.skipMobileText}>
            {t("OnboardCarousel.skip")}
          </Typography>
        </Button>
      </div>
    </div>
  );

  const DesktopOnboard = (
    <div className={classes.containerDesktop}>
      <ReactSVG
        src={closeIcon}
        className={classes.close}
        onClick={handleSkip}
      />
      <div>
        <OnboardCarouselHeader isMobile={false} slideNumber={currentSlide} />
      </div>
      <div className={classes.slidersContainerDesktop}>{GuideViews}</div>
      <div>
        <div>
          <Button
            className={classes.continueDesktop}
            onClick={(): void => handleNextSlide(currentSlide + 1)}
          >
            <Typography variant="body1" className={classes.continueDesktopText}>
              {t("OnboardCarousel.continue")}
            </Typography>
          </Button>
          <Button className={classes.skipDesktop} onClick={handleSkip}>
            <Typography variant="body1" className={classes.skipDesktopText}>
              {t("OnboardCarousel.skip")}
            </Typography>
          </Button>
        </div>
        <div className={classes.dots}>{Dots}</div>
      </div>
    </div>
  );

  return (
    <div className={classes.root}>
      {isMobile && MobileOnboard}
      {!isMobile && DesktopOnboard}
    </div>
  );
};
