import React, { useState, useEffect } from "react";
import ReactSVG from "react-svg";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { Button } from "@material-ui/core";

import {
  IconButton,
  SwipeableDrawer,
  Typography,
  AppBar,
  Toolbar,
  MenuItem,
  useMediaQuery,
  Theme,
  Dialog,
} from "@material-ui/core";

import { Props, mapStateToProps } from "./SettingsPanelTypes";
import { useStyles } from "./SettingsPanelStyles";

import backArrow from "img/Back-arrow.svg";
import { CustomSelect } from "./CustomSelect";
import {
  changeFontFamily,
  changeFontSize,
  changeFontColor,
  //TO_DO Theme type waiting for mockups for implementation
  // toogleDarkMode,
  ChangeOtherBubbleColor,
} from "store/theme/actions";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
//TO_DO Theme type waiting for mockups for implementation
// import { PaletteOptions } from "@material-ui/core/styles/createPalette";
import closeIcon from "img/Close.svg";
import i18next from "i18next";

import RightArrowIcon from "../../../img/Right-circle-arrow.svg";

const SettingsPanel: React.FC<Props> = ({
  isOpen,
  handleSettings,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const currentLanguage: string = i18next.language.replace(/[^a-z|/-/]/g, "");
  const {
    fontFamily,
    fontSize,
    fontColor,
    otherBubbleColor,
    // themeType,
  } = useSelector(mapStateToProps, shallowEqual);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const [newFontFamily, setFontFamily] = useState(fontFamily);
  const [newFontSize, setFontSize] = useState(fontSize);
  const [newFontColor, setFontColor] = useState(fontColor);
  const [newOtherBubbleColor, setOtherBubbleColor] = useState(otherBubbleColor);
  const [newLanguage, setLanguage] = useState(currentLanguage);
  const [isPrivacyPolicy, setPrivacyPolicy] = useState(false);

  const classes = useStyles({ isMobile, isPrivacyPolicy });

  useEffect(() => {
    if (isOpen) {
      setPrivacyPolicy(false);
      setFontFamily(fontFamily);
      setFontSize(fontSize);
      setFontColor(fontColor);
      setOtherBubbleColor(otherBubbleColor);
      setLanguage(currentLanguage);
    }
  }, [
    isOpen,
    fontFamily,
    fontSize,
    fontColor,
    otherBubbleColor,
    currentLanguage,
  ]);

  const customLanguage = {
    en: t("SettingsPanel.languagesOptions.en"),
    pl: t("SettingsPanel.languagesOptions.pl"),
  };

  const customFontFamilies = {
    Roboto: "Roboto",
    "Inter UI Bold": "InterUIBold",
    "Bebas Neue Bold": "BebasNeueBold",
    "Lato Bold": "LatoBold",
    "Monserrat Bold": "MonserratBold",
    "Open Dyslexic Regular": "OpenDyslexicRegular",
    "Open Dyslexic Bold": "OpenDyslexicBold",
  };

  const customFontColors = {
    "rgba(255,255,255,1)": t("SettingsPanel.fontColors.white"),
    "rgba(0,0,0,1)": t("SettingsPanel.fontColors.black"),
    "rgba(0,0,255,1)": t("SettingsPanel.fontColors.blue"),
    "rgba(0,128,0,1)": t("SettingsPanel.fontColors.green"),
    "rgba(255,255,0,1)": t("SettingsPanel.fontColors.yellow"),
    "rgba(255,165,0,1)": t("SettingsPanel.fontColors.orange"),
    "rgba(255,0,0,1)": t("SettingsPanel.fontColors.red"),
  };

  const customFontSizes = {
    "42px": t("SettingsPanel.fontSizes.large"),
    "36px": t("SettingsPanel.fontSizes.medium"),
    "16px": t("SettingsPanel.fontSizes.small"),
  };

  //TO_DO Theme type waiting for mockups for implementation
  // const customThemeColors = {
  //   dark: t("SettingsPanel.themeColors.dark"),
  //   light: t("SettingsPanel.themeColors.light")
  // };

  const customOtherBubbleColors = {
    "rgba(131,108,242,0.8)": t("SettingsPanel.otherBubbleColors.purple"),
    "rgba(255,255,255,1)": t("SettingsPanel.otherBubbleColors.white"),
    "rgba(0,0,0,1)": t("SettingsPanel.otherBubbleColors.black"),
    "rgba(0,0,255,1)": t("SettingsPanel.otherBubbleColors.blue"),
    "rgba(0,128,0,1)": t("SettingsPanel.otherBubbleColors.green"),
    "rgba(255,255,0,1)": t("SettingsPanel.otherBubbleColors.yellow"),
    "rgba(255,165,0,1)": t("SettingsPanel.otherBubbleColors.orange"),
    "rgba(255,0,0,1)": t("SettingsPanel.otherBubbleColors.red"),
  };

  const handleClose = (): void | (() => void) => {
    isPrivacyPolicy ? setPrivacyPolicy(false) : handleSettings();
  };

  const TopBar = (
    <AppBar elevation={0} position="static">
      <Toolbar className={classes.toolbar}>
        {isMobile && (
          <IconButton onClick={handleClose}>
            <ReactSVG src={backArrow} className={classes.titleIcon} />
          </IconButton>
        )}
        <Typography
          variant={isMobile ? "h5" : "h4"}
          className={classes.titleText}
        >
          {!isPrivacyPolicy
            ? t("SettingsPanel.title")
            : t("SettingsPanel.privacyPolicy.title")}
        </Typography>
        {!isMobile && (
          <ReactSVG
            src={closeIcon}
            className={classes.closeIcon}
            onClick={handleClose}
          />
        )}
      </Toolbar>
    </AppBar>
  );

  const customFontFamilyOptions = Object.entries(customFontFamilies).map(
    (value) => (
      <MenuItem key={value[0]} value={value[1]}>
        <Typography
          variant="body2"
          style={{ fontFamily: value[1], padding: "10px" }}
        >
          {value[0]}
        </Typography>
      </MenuItem>
    )
  );

  const handleFontFamily = (newFontFamily: string): void => {
    setFontFamily(newFontFamily);
  };

  const customFontColorOptions = Object.entries(customFontColors).map(
    (value) => (
      <MenuItem key={value[1]} value={value[0]}>
        <Typography
          variant="body2"
          style={{
            padding: "10px",
            color: value[0] === "rgba(255,255,255,1)" ? "grey" : value[0],
            fontFamily: fontFamily,
          }}
        >
          {value[1]}
        </Typography>
      </MenuItem>
    )
  );

  const handleFontColor = (newFontColor: string): void => {
    setFontColor(newFontColor);
  };

  const customFontSizeOptions = Object.entries(customFontSizes).map((value) => (
    <MenuItem key={value[1]} value={value[0]}>
      <Typography
        variant="body2"
        style={{ fontSize: value[0], padding: "10px", fontFamily: fontFamily }}
      >
        {value[1]}
      </Typography>
    </MenuItem>
  ));

  const handleFontSize = (newFontSize: string): void => {
    setFontSize(newFontSize);
  };
  //TO_DO Theme type waiting for mockups for implementation
  // const customThemeColorOptions = Object.entries(customThemeColors).map(
  //   value => (
  //     <MenuItem key={value[1]} value={value[0]}>
  //       <Typography variant="body2" style={{ padding: "10px" }}>
  //         {value[1]}
  //       </Typography>
  //     </MenuItem>
  //   )
  // );

  // const handleThemeColor = (newThemeColor: PaletteOptions["type"]): void => {
  //   dispatch(toogleDarkMode({ type: newThemeColor }));
  // };

  const customOtherBubbleColorOptions = Object.entries(
    customOtherBubbleColors
  ).map((value) => (
    <MenuItem key={value[1]} value={value[0]}>
      <Typography
        variant="body2"
        style={{
          padding: "10px",
          color: value[0],
          fontFamily: fontFamily,
          textShadow:
            value[0] === "rgba(255,255,255,1)"
              ? "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black"
              : "unset",
        }}
      >
        {value[1]}
      </Typography>
    </MenuItem>
  ));

  const handleOtherBubbleColor = (newOtherBubbleColor: string): void => {
    setOtherBubbleColor(newOtherBubbleColor);
    // dispatch(
    //   ChangeOtherBubbleColor({ otherBubble: { main: newOtherBubbleColor } })
    // );
  };

  const customLanguageOptions = Object.entries(customLanguage).map((value) => (
    <MenuItem key={value[1]} value={value[0]}>
      <Typography
        variant="body2"
        style={{
          padding: "10px",
          fontFamily: fontFamily,
        }}
      >
        {value[1]}
      </Typography>
    </MenuItem>
  ));

  const handleLanguage = (newLanguage: string): void => {
    setLanguage(newLanguage);
  };

  const handleSave = (): void => {
    fontFamily !== newFontFamily &&
      dispatch(changeFontFamily({ fontFamily: newFontFamily }));
    fontSize !== newFontSize &&
      dispatch(changeFontSize({ fontSize: newFontSize }));
    fontColor !== newFontColor &&
      dispatch(changeFontColor({ fontColor: newFontColor }));
    otherBubbleColor !== newOtherBubbleColor &&
      dispatch(
        ChangeOtherBubbleColor({ otherBubble: { main: newOtherBubbleColor } })
      );
    currentLanguage !== newLanguage && i18n.changeLanguage(newLanguage);
    handleSettings();
  };

  const ReturnPrivacyPolicy = (
    <div className={classes.privacyPolicy}>
      <div className={classes.privacyContent}>
        <Typography className={classes.subTitlePurple}>
          {t("SettingsPanel.privacyPolicy.subTitlePurple")}
        </Typography>
        <Typography className={classes.privacyParagraph}>
          {t("SettingsPanel.privacyPolicy.message1")}
        </Typography>
        <Typography className={classes.privacyParagraph}>
          {t("SettingsPanel.privacyPolicy.message2")}
        </Typography>
        <Typography className={classes.privacyParagraph}>
          {t("SettingsPanel.privacyPolicy.message1")}
        </Typography>
        <Typography className={classes.privacyParagraph}>
          {t("SettingsPanel.privacyPolicy.message2")}
        </Typography>
      </div>
    </div>
  );

  const ReturnSettingsOptions = (
    <div className={classes.settingsContent}>
      <CustomSelect
        column={2}
        handleChange={handleFontFamily}
        isIcon={false}
        label={t("SettingsPanel.fontFamily")}
        span={2}
        value={newFontFamily}
        menuItems={customFontFamilyOptions}
      />
      <CustomSelect
        column={2}
        span={isMobile ? 2 : 1}
        handleChange={handleFontColor}
        isIcon={true}
        label={t("SettingsPanel.fontColor")}
        value={newFontColor}
        menuItems={customFontColorOptions}
      />
      <CustomSelect
        column={isMobile ? 2 : 3}
        span={isMobile ? 2 : 1}
        handleChange={handleOtherBubbleColor}
        isIcon={true}
        label={t("SettingsPanel.otherBubble")}
        value={newOtherBubbleColor}
        menuItems={customOtherBubbleColorOptions}
      />
      <CustomSelect
        column={2}
        span={isMobile ? 2 : 1}
        handleChange={handleFontSize}
        isIcon={true}
        label={t("SettingsPanel.fontSize")}
        value={newFontSize}
        menuItems={customFontSizeOptions}
      />
      <CustomSelect
        column={isMobile ? 2 : 3}
        span={isMobile ? 2 : 1}
        handleChange={handleLanguage}
        isIcon={true}
        label={t("SettingsPanel.language")}
        value={newLanguage}
        menuItems={customLanguageOptions}
      />
      {/*TO_DO Theme type waiting for mockups for implementation */}
      {/* <CustomSelect
          column={2}
          span={2}
          handleChange={handleThemeColor}
          isIcon={true}
          label={t("SettingsPanel.themeColor")}
          value={themeType ? themeType : "light"}
          menuItems={customThemeColorOptions}
        /> */}
    </div>
  );

  const ReturnSettingsFooter = (
    <>
      <Button
        className={classes.privacyButton}
        onClick={(): void => setPrivacyPolicy(true)}
      >
        <ReactSVG src={RightArrowIcon} className={classes.rightArrowIcon} />
        <Typography className={classes.privacyButtonText}>
          {t("SettingsPanel.privacyPolicy.title")}
        </Typography>
      </Button>
      <Button onClick={handleSave} className={classes.saveButton}>
        <Typography className={classes.saveButtonText}>
          {t("SettingsPanel.saveSettings")}
        </Typography>
      </Button>
    </>
  );

  const ReturnPrivacyFooter = (
    <>
      <Button
        onClick={(): void => setPrivacyPolicy(false)}
        className={classes.saveButton}
      >
        <Typography className={classes.saveButtonText}>
          {t("SettingsPanel.privacyPolicy.button")}
        </Typography>
      </Button>
    </>
  );

  const SettingsContent = (
    <React.Fragment>
      {TopBar}
      {isPrivacyPolicy ? ReturnPrivacyPolicy : ReturnSettingsOptions}
      <div className={classes.dialogFooter}>
        {isPrivacyPolicy ? ReturnPrivacyFooter : ReturnSettingsFooter}
      </div>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {isMobile && (
        <SwipeableDrawer
          open={isOpen}
          onOpen={handleSettings}
          onClose={handleSettings}
        >
          <div className={classes.drawerRoot}>{SettingsContent}</div>
        </SwipeableDrawer>
      )}
      {!isMobile && (
        <Dialog
          className={classes.dialog}
          open={isOpen}
          onClose={handleSettings}
          classes={{ paper: classes.dialogRoot }}
        >
          {SettingsContent}
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default SettingsPanel;
