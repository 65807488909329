import { AppState } from "store";
import SpeechRecognitionService from "services/speech-recognition/speechRecognitionService";
import { OrderedMap } from "immutable";
import { Modes } from "~/../store/layout/constants/types";

interface StateProps {
  captions: OrderedMap<string, string>;
  username: string;
  isCallJoined: boolean;
  speechRecognitionService: SpeechRecognitionService | undefined;
  // peerJoinedCall: boolean;
  // rtt: string;
  // roomAddress: string;
  isAnotherJoined: boolean;
  mode: Modes;
}
export interface StyleProps {
  mode: Modes;
  isMobile: boolean;
}
export interface CaptionProps {
  mode: Modes;
  captions: OrderedMap<string, string>;
  isCallJoined: boolean;
}
export const mapStateToProps = (state: AppState): StateProps => {
  return {
    isAnotherJoined: state.chatState.isAnotherJoined,
    captions: state.chatState.captions,
    username: state.chatState.username,
    isCallJoined: state.chatState.isCallJoined,
    speechRecognitionService: state.chatState.speechRecognitionService,
    // peerJoinedCall: peer && peer.joinedCall ? true : false,
    // rtt: peer && peer.joinedCall ? peer.rtt : "",
    // roomAddress: room ? room.address : "",
    mode: state.layoutState.mode,
  };
};
