import React, { useState } from "react";

import classNames from "classnames";
import ReactSVG from "react-svg";
import SwipeableViews from "react-swipeable-views";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { mapStateToProps } from "./TipsCarouselTypes";
import { useTranslation } from "react-i18next";

import { useStyles } from "./TipsCarouselStyles";
import { Typography, IconButton } from "@material-ui/core";

import { changeTips } from "store/layout/actions";

import closeIcon from "img/Close.svg";
import nextIcon from "img/Arrow.svg";
import InviteIcon from "img/Invite.svg";

import { isMobile } from "react-device-detect";

import { Modes } from "~/../store/layout/constants/types";

export const TipsCarousel: React.FC<{}> = (): JSX.Element => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const { mode } = useSelector(mapStateToProps, shallowEqual);
  const classes = useStyles({
    isFullMode: mode === Modes.full,
    isMobile: isMobile,
  });
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const handleNextSlide = (): void => {
    currentSlide === 2
      ? dispatch(changeTips({ isTips: false }))
      : setCurrentSlide(currentSlide + 1);
  };

  const handleClose = (): void => {
    dispatch(changeTips({ isTips: false }));
  };

  const Dot = ({ dotNumber }: { dotNumber: number }): JSX.Element => (
    <div
      className={classNames(classes.defaultDot, {
        [classes.activeDot]: currentSlide === dotNumber,
      })}
    />
  );

  const nextButton = (
    <IconButton className={classes.buttonNext} onClick={handleNextSlide}>
      <ReactSVG src={nextIcon} className={classes.nextIcon} />
    </IconButton>
  );

  const closeButton = (
    <IconButton className={classes.buttonClose} onClick={handleClose}>
      <ReactSVG src={closeIcon} className={classes.closeIcon} />
    </IconButton>
  );

  const Dots = (
    <div>
      <Dot dotNumber={0} />
      <Dot dotNumber={1} />
      <Dot dotNumber={2} />
    </div>
  );

  const firstSwipeableView = (
    <div className={classes.swipeableView}>
      <Typography variant="body1" className={classes.titleText}>
        <strong>{t("TipsCarousel.firstViewTitle")}</strong>
      </Typography>
      <Typography variant="body1" className={classes.descriptionText}>
        {t("TipsCarousel.firstViewDescription1")}
        <ReactSVG
          src={InviteIcon}
          className={classes.inviteIcon}
          wrapper="span"
        />
        {t("TipsCarousel.firstViewDescription2")}
      </Typography>
    </div>
  );

  return (
    <div className={classes.root}>
      <SwipeableViews
        onChangeIndex={handleNextSlide}
        resistance={true}
        index={currentSlide}
        disabled={false}
        enableMouseEvents
      >
        {firstSwipeableView}
        {firstSwipeableView}
        {firstSwipeableView}
      </SwipeableViews>
      <div className={classes.controlDiv}>
        {closeButton}
        {Dots}
        {nextButton}
      </div>
    </div>
  );
};
