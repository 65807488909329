import { AppState } from "store";
import { Modes } from "~/../store/layout/constants/types";

export interface StyleProps {
  isFullMode: boolean;
  isMobile: boolean;
}

interface StateProps {
  mode: Modes;
}
export const mapStateToProps = (state: AppState): StateProps => {
  return {
    mode: state.layoutState.mode,
  };
};
