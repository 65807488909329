import React from "react";
import ReactSVG from "react-svg";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import { useStyles } from "./CallPageStyles";
import CallControl from "./call-control/";
import RemoteMedia from "./remote-media/RemoteMedia";
import LocalVideo from "./local-video/LocalVideo";

import CustomSnackbar from "components/common/CustomSnackbar";
import OccupiedConversationDialog from "../common/OccupiedConversationDialog";
// import UnavailableLinkDialog from "../../common/unavailable-link-dialog/UnavailableLinkDialog";
import BrowserDialog from "../common/BrowserDialog";

import logo from "img/Logo.svg";
import { CallPageProps } from "./CallPageTypes";

const CallPage = function (props: CallPageProps): JSX.Element {
  const c = useStyles();
  const { t } = useTranslation();
  const { isAnotherJoined, snackbar, startedTalking, isCallJoined } = props;

  const PreviewPart = (
    <div className={c.captionsPreview}>
      <div className={c.logoDiv}>
        <div>
          <ReactSVG wrapper="span" src={logo} />
        </div>
      </div>
      <Typography className={c.captionsPreviewText} variant="body1">
        {t("CallPage.previewTitle")}
      </Typography>
    </div>
  );
  return (
    <div className={c.root}>
      <LocalVideo />
      <RemoteMedia />
      {!isAnotherJoined && !startedTalking && !isCallJoined && PreviewPart}
      <CallControl />
      <CustomSnackbar
        open={snackbar.open}
        date={snackbar.date}
        message={snackbar.message}
        icon={snackbar.icon}
        isClosable={snackbar.isClosable}
        isPurple={snackbar.isPurple}
      />
      {/* TO_DO temprorary commented out  */}
      {/* <UnavailableLinkDialog /> */}
      <OccupiedConversationDialog />
      <BrowserDialog />
    </div>
  );
};

export default CallPage;
