export enum Modes {
  full = "full",
  half = "half",
  text = "full-text",
}

export interface LayoutState {
  isPermissionDialog: boolean;
  isOnboarding: boolean;
  isTips: boolean;
  mode: Modes;
  isSplashScreen: boolean;
  captionsListHeight: number;
  isUnavailableLinkDialog: boolean;
  isOccupiedConversationDialog: boolean;
  isBrowserDialog: boolean;
}

export const CHANGE_PERMISSION_DIALOG_VISIBILITY =
  "CHANGE_PERMISSION_DIALOG_VISIBILITY";
export const CHANGE_ONBOARDING = "CHANGE_ONBOARDING";
export const CHANGE_TIPS = "CHANGE_TIPS";
export const CHANGE_MODE = "CHANGE_MODE";
export const SET_SPLASH_SCREEN = "SET_SPLASH_SCREEN";
export const CHANGE_CAPTIONS_LIST_HEIGHT = "CHANGE_CAPTIONS_LIST_HEIGHT";
export const CHANGE_UNAVAILABLE_LINK_DIALOG = "CHANGE_UNAVAILABLE_LINK_DIALOG";
export const CHANGE_OCCUPIED_CONVERSATION_DIALOG =
  "CHANGE_OCCUPIED_CONVERSATION_DIALOG";
export const CHANGE_BROWSER_DIALOG = "CHANGE_BROWSER_DIALOG";

export interface ChangePermissionDialogVisibilityAction {
  type: typeof CHANGE_PERMISSION_DIALOG_VISIBILITY;
  payload: {
    isPermissionDialog: boolean;
  };
}

export interface ChangeOnboardingAction {
  type: typeof CHANGE_ONBOARDING;
  payload: {
    isOnboarding: boolean;
  };
}

export interface ChangeTipsAction {
  type: typeof CHANGE_TIPS;
  payload: {
    isTips: boolean;
  };
}

export interface ChangeModeAction {
  type: typeof CHANGE_MODE;
  payload: {
    mode: Modes;
  };
}

export interface SetSplashScreenAction {
  type: typeof SET_SPLASH_SCREEN;
  payload: {
    isSplashScreen: boolean;
  };
}

export interface ChanegCaptionsListHeight {
  type: typeof CHANGE_CAPTIONS_LIST_HEIGHT;
  payload: {
    captionsListHeight: number;
  };
}

export interface ChangeUnavailableDialog {
  type: typeof CHANGE_UNAVAILABLE_LINK_DIALOG;
  payload: {
    isUnavailableLinkDialog: boolean;
  };
}
export interface ChangeOccupiedConversationDialog {
  type: typeof CHANGE_OCCUPIED_CONVERSATION_DIALOG;
  payload: {
    isOccupiedConversationDialog: boolean;
  };
}

export interface ChangeBrowserDialog {
  type: typeof CHANGE_BROWSER_DIALOG;
  payload: {
    isBrowserDialog: boolean;
  };
}

export type LayoutActionTypes =
  | ChangePermissionDialogVisibilityAction
  | ChangeOnboardingAction
  | ChangeTipsAction
  | ChangeModeAction
  | SetSplashScreenAction
  | ChanegCaptionsListHeight
  | ChangeUnavailableDialog
  | ChangeOccupiedConversationDialog
  | ChangeBrowserDialog;
