import {
  PaletteOptions,
  SimplePaletteColorOptions,
} from "@material-ui/core/styles/createPalette";
import { ThemeOptions } from "@material-ui/core/styles/createMuiTheme";

declare module "@material-ui/core/styles/createMuiTheme" {
  interface Theme {
    customPalette: CustomPaletteOptions;
    customCaptions: CustomCaptions;
  }
  interface ThemeOptions {
    customPalette: CustomPaletteOptions;
    customCaptions: CustomCaptions;
  }
}

interface CustomPaletteOptions {
  otherBubble: SimplePaletteColorOptions;
  myBubble: SimplePaletteColorOptions;
}

interface CustomCaptions {
  fontFamily: string;
  fontSize: string;
  fontColor: string;
}

export interface GlobalThemeState extends ThemeOptions {
  palette: PaletteOptions;
  customPalette: CustomPaletteOptions;
  customCaptions: CustomCaptions;
}

export const TOOGLE_DARK_MODE = "TOOGLE_DARK_MODE";
export const CHANGE_FONT_SIZE = "CHANGE_FONT_SIZE";
export const CHANGE_FONT_FAMILY = "CHANGE_FONT_FAMILY";
export const CHANGE_FONT_COLOR = "CHANGE_FONT_COLOR";
export const CHANGE_OTHER_BUBBLE_COLOR = "CHANGE_OTHER_BUBBLE_COLOR";

export interface ToogleDarkModeAction {
  type: typeof TOOGLE_DARK_MODE;
  payload: {
    type: PaletteOptions["type"];
  };
}

export interface ChangeFontSizeAction {
  type: typeof CHANGE_FONT_SIZE;
  payload: {
    fontSize: string;
  };
}

export interface ChangeFontFamilyAction {
  type: typeof CHANGE_FONT_FAMILY;
  payload: {
    fontFamily: string;
  };
}

export interface ChangeFontColorAction {
  type: typeof CHANGE_FONT_COLOR;
  payload: {
    fontColor: string;
  };
}

export interface ChangeOtherBubbleColorAction {
  type: typeof CHANGE_OTHER_BUBBLE_COLOR;
  payload: {
    otherBubble: SimplePaletteColorOptions;
  };
}

export type GlobalThemeActionTypes =
  | ToogleDarkModeAction
  | ChangeFontSizeAction
  | ChangeFontFamilyAction
  | ChangeFontColorAction
  | ChangeOtherBubbleColorAction;
