import { ThemeOptions } from "@material-ui/core/styles/createMuiTheme";
import { AppState } from "./store";

interface StateProps {
  globalTheme: ThemeOptions;
}

export type Props = StateProps;

export const mapStateToProps = (state: AppState): StateProps => {
  return {
    globalTheme: state.globalTheme,
  };
};
