/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import {
  Dialog,
  Button,
  DialogActions,
  Theme,
  Typography,
} from "@material-ui/core";

import { AppState } from "store";
import { clearStore } from "store/chat/actions";
import { changeOccupiedConversationDialog } from "store/layout/actions";

import ReactSVG from "react-svg";
import CallBusy from "../../img/Call-busy.svg";
import closeIcon from "img/Close.svg";

import useReactRouter from "use-react-router";

export interface StyleProps {
  isMobile: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  dialogRoot: {
    "&> .MuiBackdrop-root": {
      backgroundColor: "rgba(11, 8, 27, 0.9)",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0",
    },
  },
  root: (props: StyleProps) => ({
    width: props.isMobile ? "auto" : "596px",
    height: props.isMobile ? "auto" : "703px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    paddingBottom: "4vh",
    margin: props.isMobile ? "0" : "auto",
  }),
  closeIcon: {
    alignSelf: "flex-end",
    position: "absolute",
    top: 0,
    padding: "15px",
    "&:hover": {
      cursor: "pointer",
    },
    "& svg g": {
      fill: "#818895",
    },
  },
  icon: (props: StyleProps) => ({
    marginTop: props.isMobile ? "5vh" : "7vh",
    width: props.isMobile ? "100px" : "120px",
    margin: "0 auto",
    "& svg": {
      height: props.isMobile ? "100px" : "120px",
      width: props.isMobile ? "100px" : "120px",
    },
    "&> div": {
      height: props.isMobile ? "100px" : "120px",
      width: props.isMobile ? "100px" : "120px",
      display: "flex",
    },
  }),
  title: (props: StyleProps) => ({
    marginTop: props.isMobile ? "24px" : "7vh",
    color: "#3F4756",
    fontSize: props.isMobile ? "24px" : "36px",
    textAlign: "center",
    maxWidth: "340px",
    padding: props.isMobile ? "0 24px" : "0",
  }),
  descriptionStart: (props: StyleProps) => ({
    padding: props.isMobile ? "0 24px" : "0 20px",
    marginTop: props.isMobile ? "8px" : "2vh",
    fontSize: props.isMobile ? "14px" : "16px",
    lineHeight: "150%",
    textAlign: "center",
    color: "#818895",
  }),
  descriptionEnd: (props: StyleProps) => ({
    padding: props.isMobile ? "0 24px" : "0 4vh",
    marginTop: props.isMobile ? "8px" : "2vh",
    fontSize: props.isMobile ? "14px" : "16px",
    lineHeight: "150%",
    textAlign: "center",
    color: "#818895",
  }),
  sendText: {
    color: theme.palette.primary.light,
  },
  keepGoingButton: (props: StyleProps) => ({
    backgroundColor: "transparent",
    textTransform: "none",
    display: "inline-flex",
    border: "1px solid rgba(131,108,242,0.4)",
    borderRadius: "8px",
    height: "60px",
    minWidth: props.isMobile ? "100%" : "180px",
    marginLeft: props.isMobile ? "0" : "20px",
  }),
  keepGoingText: {
    color: theme.palette.secondary.light,
  },
  dialogActions: (props: StyleProps) => ({
    marginTop: props.isMobile ? "24px" : "4vh",
    dispaly: "flex",
    flexDirection: props.isMobile ? "column" : "row",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "0 24px",
    },
  }),
  link: {
    textDecoration: "none",
    width: "100%",
  },
}));

interface StateProps {
  open: boolean;
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    open: state.layoutState.isOccupiedConversationDialog,
  };
};

const OccupiedConversationDialog = (): JSX.Element => {
  const { history } = useReactRouter();

  const { open } = useSelector(mapStateToProps, shallowEqual);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const classes = useStyles({ isMobile: isMobile });

  const handleClose = () => {
    dispatch(
      changeOccupiedConversationDialog({ isOccupiedConversationDialog: false })
    );
    dispatch(clearStore());
    history.push("/");
  };

  return (
    <Dialog open={open} className={classes.dialogRoot}>
      <div className={classes.root}>
        <ReactSVG
          src={closeIcon}
          className={classes.closeIcon}
          onClick={handleClose}
        />
        <div>
          <ReactSVG src={CallBusy} className={classes.icon} />
          <Typography variant="h5" className={classes.title}>
            {t("OccupiedConversationDialog.title")}
          </Typography>
        </div>
        <Typography variant="h5" className={classes.descriptionEnd}>
          {t("OccupiedConversationDialog.description")}
        </Typography>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleClose} className={classes.keepGoingButton}>
            <Typography variant="body1" className={classes.keepGoingText}>
              {t("OccupiedConversationDialog.cancel")}
            </Typography>
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default OccupiedConversationDialog;
