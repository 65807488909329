import React, { useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import useReactRouter from "use-react-router";

import { clearStore, setCreator } from "store/chat/actions";
import { mapStateToProps } from "./MainPageTypes";
import SettingsPanel from "../common/SettingsPanel/SettingsPanel";
import { OnboardCarousel } from "./onboard-carousel/OnboardCarousel";
import CookiesSnackbar from "../common/CookiesSnackbar";

// import { SplashScreen } from "./splash-screen/SplashScreen";
import { MainPageContent } from "./MainPage";
import { useSettings } from "components/common/SettingsPanel/useSettings";
const hri = require("human-readable-ids").hri;

const MainPage: React.FC<{}> = (): JSX.Element => {
  const { isOnboarding } = useSelector(mapStateToProps, shallowEqual);
  const dispatch = useDispatch();
  const { history } = useReactRouter();
  const { isSettings, handleSettings } = useSettings();

  useEffect(() => {
    dispatch(clearStore());
  }, [dispatch]);

  const handleCreate = (): void => {
    dispatch(setCreator({ isCreator: true }));
    history.push(`/call/${hri.random()}`);
  };

  return (
    <React.Fragment>
      {/* Temproraryhidden spalash screen */}
      {/* <SplashScreen /> */}
      {!isOnboarding && (
        <SettingsPanel
          isOpen={isSettings}
          handleSettings={(): void => {
            handleSettings();
          }}
        />
      )}
      {isOnboarding && <OnboardCarousel />}
      <MainPageContent
        handleCreate={handleCreate}
        handleSettings={handleSettings}
      />
      <CookiesSnackbar />
    </React.Fragment>
  );
};

export default MainPage;
