import { AppState } from "store";
import { Modes } from "../../../store/layout/constants/types";

interface StateProps {
  mode: Modes;
  isCreator: boolean;
  isCallJoined: boolean;
  isAnotherJoined: boolean;
}

export interface StyleProps {
  isHalfMode: boolean;
  isBrowser: boolean;
}

export const mapStateToProps = (state: AppState): StateProps => {
  return {
    mode: state.layoutState.mode,
    isCreator: state.chatState.isCreator,
    isCallJoined: state.chatState.isCallJoined,
    isAnotherJoined: state.chatState.isAnotherJoined,
  };
};
