import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";

import { Snackbar, SnackbarContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import closeIcon from "img/Close.svg";
import ReactSVG from "react-svg";
import { useTranslation } from "react-i18next";
import { useSelector, shallowEqual } from "react-redux";
import { AppState } from "store";

const useStyles = makeStyles(() => ({
  snackbarRoot: {
    maxWidth: "100vw",
    marginTop: "10px",
  },
  snackbarContent: {
    backgroundColor: "#836CF2",
    margin: "30px 30px",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
  },
  message: {
    color: "#FFFFFF",
    fontSize: "14px",
    display: "flex",
    marginLeft: "5px",
    alignItems: "center",
  },
  icon: {
    marginRight: "10px",
    width: "20px",
    height: "20px",
    "& svg": {
      height: "20px",
      width: "20px",
    },
    "&> div": {
      height: "20px",
      display: "flex",
    },
  },
  messageText: {
    marginTop: "2px",
  },
  messageLink: {
    marginTop: "2px",
    marginLeft: "4px",
    lineHeight: "14px",
    borderBottom: "1px solid white",
    cursor: "pointer",
    "&:hover": { borderBottom: "none" },
  },
  closeIcon: {
    width: "12px",
    height: "12px",
    margin: "20px",
    "&:hover": {
      cursor: "pointer",
    },
    "& svg": {
      height: "12px",
      width: "12px",
    },
    "& g": {
      fill: "white",
    },
    "&> div": {
      height: "12px",
      display: "flex",
    },
  },
}));

interface StateProps {
  isOnboarding: boolean;
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    isOnboarding: state.layoutState.isOnboarding,
  };
};

export default function CookiesSnackbar(): JSX.Element {
  const [isOpen, setOpen] = useState(false);
  const c = useStyles();
  const { t } = useTranslation();
  const { isOnboarding } = useSelector(mapStateToProps, shallowEqual);

  const cookies = new Cookies();

  useEffect(() => {
    const isAcceppted = cookies.get("acceptted");
    isOnboarding || isAcceppted ? setOpen(false) : setOpen(true);
  }, [isOnboarding, cookies]);

  const handleCookies = (): void => {
    const expireDate = new Date();
    expireDate.setMonth(expireDate.getMonth() + 8);
    cookies.set("acceptted", "true", {
      path: "/",
      expires: expireDate,
    });
    setOpen(false);
  };

  return (
    <Snackbar
      open={isOpen}
      onClose={(event, reason): void => {
        reason === "clickaway" ? setOpen((p) => p) : setOpen(false);
      }}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      disableWindowBlurListener
      className={c.snackbarRoot}
    >
      <SnackbarContent
        className={c.snackbarContent}
        message={
          <span className={c.message}>
            <div className={c.messageText}>
              {t("CookiesSnackbar.message")}
              <span className={c.messageLink}>
                {t("CookiesSnackbar.privacyPolicy")}
              </span>
            </div>
          </span>
        }
        action={[
          <ReactSVG
            key="close"
            src={closeIcon}
            className={c.closeIcon}
            onClick={handleCookies}
          />,
        ]}
      />
    </Snackbar>
  );
}
