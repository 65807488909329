import React, { useReducer, useState } from "react";
import { State, StyleProps } from "./types";
import { useTranslation } from "react-i18next";
import {
  makeStyles,
  useMediaQuery,
  Theme,
  TextField,
  Typography,
  Button,
} from "@material-ui/core";

const useStyles = makeStyles<Theme, StyleProps>((): any => ({
  ErrorMsg: {
    marginTop: "20px",
    color: (props): any => (props.sendErr ? "red" : "transparent"),
    transition: "0.4s ease-in-out",
  },
  sendButton: {
    height: "50px",
    width: "calc(100% - 40px)",
    backgroundColor: "rgba(131,108,242,1)",
    color: "white",
    maxWidth: "166px",
  },
  label: {
    fontWeight: 900,
    fontSize: "16px",
    lineHeight: "20px",
    marginBottom: "16px",
    transition: "0.4s ease-in-out",
  },
  Form: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    margin: "0 auto",
    maxWidth: "696px",
    paddingBottom: "50px",
    "> div": {
      display: "flex",
      flexDirection: "column",
    },
  },
  Input: {
    height: "56px",
    borderRadius: "10px",
    border: "none",
    fontWeight: 900,
    "&:focus": {
      outline: "none",
    },
    "&::placeholder": {
      color: "#8f9fa9",
    },
  },
  InputGroup: {
    width: "45%",
    marginTop: "40px",
    display: "flex",
    flexDirection: "column",
  },
  InputGroupArea: {
    width: "100%",
    marginTop: "40px",
    display: "flex",
    flexDirection: "column",
  },
  Textarea: {
    width: "100%",
    minHeight: "100px",
    borderRadius: "10px",
    border: "none",
    fontWeight: 900,
    "&:focus": {
      outlinze: "none",
    },
    "&::placeholder": {
      color: "#8f9fa9",
    },
  },
  Wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    margin: "0",
    padding: "0 30px",
  },
  title: (props: StyleProps): any => ({
    color: "#3F4756",
    margin: props.isMobile ? "12px 0" : "5vh 0 5vh 0",
    textAlign: "center",
    fontSize: props.isMobile ? "22px" : "36px",
    lineHeight: "38px",
  }),
}));

const initialState: State = {
  name: { value: "", isValid: true },
  surname: { value: "", isValid: true },
  email: { value: "", isValid: true },
  phone: { value: "", isValid: true },
  question: { value: "", isValid: true },
};

function reducer(state, { field, value, isValid }): State {
  return {
    ...state,
    [field]: { value: value, isValid: isValid },
  };
}

function validate(field, value): boolean {
  switch (field) {
    case "name":
      return value.length > 2 ? true : false;
    case "surname":
      return value.length > 2 ? true : false;
    case "email":
      return value.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
        ? true
        : false;
    case "phone":
      return value.length > 6 ? true : false;
    case "question":
      return value.length > 2 ? true : false;
    default:
      return false;
  }
}

export function ContactForm(): JSX.Element {
  const { t } = useTranslation();

  const [state, dispatch] = useReducer(reducer, initialState);
  const [formValid, setFormValid] = useState(false);
  const [isSend, setIsSend] = useState(false);
  const [sendErr, setSendErr] = useState(false);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const c = useStyles({ isMobile, sendErr });

  const encode = (data): string => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  const checkIsFormValid = (): void => {
    Object.keys(state).every(
      (item) => state[item].isValid === true && state[item].value !== ""
    ) && setFormValid(true);
  };

  const checkInputsError = (): void => {
    Object.keys(state).forEach((item) => {
      const value = state[item].value;
      const itemValid = validate(item, value);
      dispatch({ field: item, value, isValid: itemValid });
    });
  };

  const onChange = (e): void => {
    const isValid = validate(e.target.name, e.target.value);
    dispatch({ field: e.target.name, value: e.target.value, isValid });
    checkIsFormValid();
  };

  const handleSubmit = (e): any => {
    e.preventDefault();
    checkInputsError();
    checkIsFormValid();
    const formBody = JSON.parse(JSON.stringify(state));

    // eslint-disable-next-line
    Object.keys(formBody).map(function (key) {
      formBody[key] = formBody[key].value;
    });

    if (formValid) {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact", ...formBody }),
      })
        .then(() => setIsSend(true))
        .catch((error) => {
          setSendErr(true);
          setTimeout(() => setSendErr(false), 5000);
        });
    } else {
      setSendErr(true);
      setTimeout(() => setSendErr(false), 5000);
    }
  };

  const { name, surname, email, phone, question } = state;
  return (
    <div className={c.Wrapper}>
      {isSend ? (
        <pre className={c.ThanksMassage}>{t("ContactForm.thankYou")}</pre>
      ) : (
        <>
          <Typography variant="h5" className={c.title}>
            {t("ContactForm.doYouHaveAnyFeedback")}{" "}
          </Typography>

          <form
            className={c.Form}
            name="contact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="contact" />
            <div className={c.InputGroup}>
              <label className={c.label}>{t("ContactForm.name")}</label>
              <TextField
                className={c.Input}
                value={name.value}
                name="name"
                placeholder={t("ContactForm.enter")}
                onChange={onChange}
              />
            </div>
            <div className={c.InputGroup}>
              <label className={c.label}>{t("ContactForm.surname")}</label>
              <TextField
                className={c.Input}
                value={surname.value}
                name="surname"
                placeholder={t("ContactForm.enter")}
                onChange={onChange}
              />
            </div>
            <div className={c.InputGroup}>
              <label className={c.label}>{t("ContactForm.email")}</label>
              <TextField
                className={c.Input}
                value={email.value}
                type="email"
                name="email"
                placeholder={t("ContactForm.enter")}
                onChange={onChange}
              />
            </div>
            <div className={c.InputGroup}>
              <label className={c.label}>{t("ContactForm.telephone")}</label>
              <TextField
                className={c.Input}
                value={phone.value}
                type="tel"
                name="phone"
                placeholder={t("ContactForm.enter")}
                onChange={onChange}
              />
            </div>
            <div className={c.InputGroupArea}>
              <label className={c.label}>{t("ContactForm.feedback")}</label>
              <TextField
                className={c.Textarea}
                value={question.value}
                name="question"
                placeholder={t("ContactForm.messageContent")}
                onChange={onChange}
                multiline={true}
                rows={1}
                rowsMax={4}
              />
            </div>

            <Button className={c.sendButton} type="submit">
              {t("ContactForm.send")}
            </Button>

            {sendErr && (
              <span className={c.ErrorMsg}>
                {t("ContactForm.failedToSendForm")}
              </span>
            )}
          </form>
        </>
      )}
    </div>
  );
}
