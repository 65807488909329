import { createStore, applyMiddleware, Store } from "redux";
import thunk from "redux-thunk";
import { combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import throttle from "lodash/throttle";

import chatReducer, { initialChatState } from "./chat/reducers";
import layoutReducer, { initialLayoutState } from "./layout/reducers";

import globalThemeReducer, { initialGlobalThemeState } from "./theme/reducers";
import {
  loadState,
  saveStateToLocalStorage,
  saveStateToSessionStorage,
} from "./state-utils/storageUtils";
import { GlobalThemeState } from "./theme/constants/types";
import { ChatState } from "./chat/constants/types";
import { LayoutState } from "./layout/constants/types";

declare global {
  interface Window {
    store: Store;
  }
}

const rootReducer = combineReducers({
  chatState: chatReducer,
  layoutState: layoutReducer,
  globalTheme: globalThemeReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export interface PersistedState {
  chatState?: Partial<ChatState> | undefined;
  layoutState?: Partial<LayoutState> | undefined;
  globalTheme?: Partial<GlobalThemeState> | undefined;
}

const persistedState = ({
  localState,
  sessionState,
}: {
  localState: PersistedState | undefined;
  sessionState: PersistedState | undefined;
}): PersistedState => {
  if (localState && !sessionState) {
    return {
      chatState: {
        ...initialChatState,
        ...localState.chatState,
      },
      layoutState: {
        ...initialLayoutState,
        ...localState.layoutState,
      },
      globalTheme: {
        ...initialGlobalThemeState,
        ...localState.globalTheme,
      },
    };
  } else if (!localState && sessionState) {
    return {
      chatState: {
        ...initialChatState,
        ...sessionState.chatState,
      },
      layoutState: {
        ...initialLayoutState,
        ...sessionState.layoutState,
      },
      globalTheme: undefined,
    };
  } else if (localState && sessionState) {
    return {
      chatState: {
        ...initialChatState,
        ...localState.chatState,
        ...sessionState.chatState,
      },
      layoutState: {
        ...initialLayoutState,
        ...localState.layoutState,
        ...sessionState.layoutState,
      },
      globalTheme: {
        ...initialGlobalThemeState,
        ...localState.globalTheme,
      },
    };
  } else {
    return {
      chatState: undefined,
      globalTheme: undefined,
    };
  }
};

export const store = createStore(
  rootReducer,
  persistedState(loadState<PersistedState>()),
  composeWithDevTools(applyMiddleware(thunk))
);

store.subscribe(
  throttle((): void => {
    saveStateToLocalStorage<PersistedState>({
      chatState: {
        isLocalAudio: store.getState().chatState.isLocalAudio,
        isLocalVideo: store.getState().chatState.isLocalVideo,
      },
      layoutState: {
        isOnboarding: store.getState().layoutState.isOnboarding,
        isTips: store.getState().layoutState.isTips,
        mode: store.getState().layoutState.mode,
      },
      globalTheme: store.getState().globalTheme,
    });
    saveStateToSessionStorage<PersistedState>({
      chatState: {
        isCallJoined: store.getState().chatState.isCallJoined,
        isCreator: store.getState().chatState.isCreator,
        isAnotherJoined: store.getState().chatState.isAnotherJoined,
      },
      layoutState: {
        isSplashScreen: store.getState().layoutState.isSplashScreen,
      },
    });
  }, 1000)
);
