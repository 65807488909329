/* eslint-disable @typescript-eslint/no-this-alias */
import AgoraRTM from "agora-rtm-sdk";
import * as UUID from "uuid";

const APP_ID = process.env.REACT_APP_AGORA_API_KEY
  ? process.env.REACT_APP_AGORA_API_KEY
  : "";
const client: any = AgoraRTM.createInstance(APP_ID);
const USER_ID: string = UUID.v4();

class RtmInterface {
  client = client;
  channelName = "";
  channel: any = undefined;
  logged = false;

  createChannel(roomName: string): void {
    const channel = client.createChannel(roomName);
    this.channel = channel;
  }

  joinChannel(): void {
    this.channel
      .join()
      .then(() => {
        console.log("Rtm joined");
      })
      .catch((err: string) => {
        console.log(err, "rtm error");
      });
  }

  loginRTM(): void {
    if (!this.logged) {
      client
        .login({ token: undefined, uid: USER_ID })
        .then(() => {
          this.logged = true;
          console.log("AgoraRTM client login success");
          this.joinChannel();
        })
        .catch((err: string) => {
          console.log("AgoraRTM client login failure", err);
        });
    }
  }

  channelSendMessage(text: string, id: string): void {
    const textToSend = id + text;
    if (text !== "") {
      this.channel.sendMessage({ text: textToSend }).catch((err: string) => {
        console.error("RTM ERROR:" + err);
      });
    }
  }
  setChannel = (name: string): void => {
    const me = this;
    me.channelName = name;
  };
}

export default new RtmInterface();
