import React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
  Typography,
} from "@material-ui/core";

import { mapStateToProps } from "./PermissionDialogTypes";
import { changePermissionDialogVisibility } from "store/layout/actions";

const useStyles = makeStyles(() => ({
  OkButton: {
    color: "white",
    boxShadow: "0px 8px 12px rgba(255, 146, 166, 0.25)",
  },
}));

const PermissionDialog: React.FC<{}> = (): JSX.Element => {
  const { isPermissionDialog } = useSelector(mapStateToProps, shallowEqual);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();

  const handleCloseDialog = (): void => {
    dispatch(
      changePermissionDialogVisibility({
        isPermissionDialog: !isPermissionDialog,
      })
    );
  };

  return (
    <Dialog open={isPermissionDialog} onClose={(): void => handleCloseDialog()}>
      <DialogTitle>
        <Typography variant="h5">
          {t("PermissionDialog.dialogTitle")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1">
            {t("PermissionDialog.dialogDescription")}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(): void => handleCloseDialog()}
          color="primary"
          variant="contained"
          className={classes.OkButton}
        >
          <Typography variant="body1">
            {t("PermissionDialog.dialogOk")}
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PermissionDialog;
