/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";
import { useSelector, shallowEqual } from "react-redux";

import { makeStyles } from "@material-ui/styles";
import { Theme, useMediaQuery } from "@material-ui/core";

import { mapStateToProps, StyleProps } from "./LocalVideoTypes";

import personBackgroundPurple from "img/Person-background-purple.svg";
// import personBackgroundGrey from "img/Person-background-grey.svg";

import { SpeechIndicator } from "../speech-indicator/SpeechIndicator";

import { Modes } from "~/../store/layout/constants/types";

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  localVideo: (props: StyleProps) => ({
    overflow: "hidden",
    position: "absolute",
    right:
      props.isHalfMode && props.isDesktop
        ? "510px"
        : props.isDesktop
        ? "70px"
        : "40px",
    top: props.isDesktop ? "70px" : "40px",
    zIndex: 99,
    borderRadius: "9999px",
    display: "flex",
    "& #agora_local": {
      overflow: "hidden",
      height: props.isDesktop ? "124px" : "90px",
      width: props.isDesktop ? "124px" : "90px",
      border: "2px solid rgba(131,108,242,1)",
      borderRadius: "9999px",
      objectFit: "cover",
      backgroundImage: `url(${personBackgroundPurple})`,
      backgroundSize: "cover",
    },
    "& img": {
      height: props.isDesktop ? "100px" : "60px",
      width: props.isDesktop ? "100px" : "60px",
      borderRadius: "9999px",
      objectFit: "cover",
    },
    personBackgroundGrey: {
      border: "2px solid #3E4757",
    },
    personBackgroundPurple: {
      border: "2px solid rgba(131,108,242,1)",
    },
  }),
  speechIndicator: (props: StyleProps) => ({
    transform: props.isDesktop ? "none" : "scale(.7)",
    height: props.isDesktop ? "200px" : "60px",
    width: props.isDesktop ? "200px" : "60px",
    position: "absolute",
    right:
      props.isHalfMode && props.isDesktop
        ? "510px"
        : props.isDesktop
        ? "32px"
        : 104,
    top: props.isDesktop ? "32px" : 6,
    zIndex: 99,
  }),
}));

const LocalVideo: React.FC<{}> = (): JSX.Element => {
  // const speechIndicatorRef = useSpeechIndicator();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const {
    isLocalVideo,
    // isLocalVideoStream,
    mode,
    // callJoined,
    // isCreator
  } = useSelector(mapStateToProps, shallowEqual);

  const classes = useStyles({
    isDesktop: !isMobile,
    isHalfMode: mode === Modes.half,
  });
  return isLocalVideo ? (
    <div className={classes.Wrapper}>
      <div className={classes.localVideo}>
        <div id="agora_local" />

        {/* 
      {localVideo &&
        localVideo.track &&
        console.log("VIDEO SETTINGS", localVideo.track.getSettings())}
      {isLocalVideoStream && isLocalVideo ? (
        <Video media={localVideo} />
      ) : callJoined && !isCreator ? (
        <img
          className={classes.personBackgroundGrey}
          src={personBackgroundGrey}
          alt="Brak video lokalnego"
        />
      ) : (
        <img
          className={classes.personBackgroundPurple}
          src={personBackgroundPurple}
          alt="Brak video lokalnego"
        />
      )} */}
      </div>
      <div className={classes.speechIndicator}>
        <SpeechIndicator />
      </div>
    </div>
  ) : (
    <div />
  );
};

export default LocalVideo;
