import { AppState } from "store";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";

interface OwnProps {
  isOpen: boolean;
  handleSettings: () => void;
}
interface StateProps {
  isDarkMode: boolean;
  fontFamily: string;
  fontSize: string;
  fontColor: string;
  themeType: PaletteOptions["type"];
  otherBubbleColor: string;
}
export interface StyleProps {
  isMobile: boolean;
  isPrivacyPolicy: boolean;
}

export type Props = OwnProps;

export const mapStateToProps = (state: AppState): StateProps => {
  return {
    isDarkMode: state.globalTheme.palette.type === "dark" ? true : false,
    fontFamily: state.globalTheme.customCaptions.fontFamily,
    fontSize: state.globalTheme.customCaptions.fontSize,
    fontColor: state.globalTheme.customCaptions.fontColor,
    themeType: state.globalTheme.palette.type,
    otherBubbleColor: state.globalTheme.customPalette.otherBubble.light
      ? state.globalTheme.customPalette.otherBubble.light
      : state.globalTheme.customPalette.otherBubble.main,
  };
};
