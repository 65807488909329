import SpeechRecognitionService from "services/speech-recognition/speechRecognitionService";
import { OrderedMap } from "immutable";

export enum ConnectionState {
  disconnected = "DISCONNECTED",
  connecting = "CONNECTING",
  connected = "CONNECTED",
  disconnecting = "DISCONNECTING",
}

export interface ChatState {
  captions: OrderedMap<string, string>;
  username: string;
  isRemoteVideo: boolean;
  isLocalVideo: boolean;
  isLocalAudio: boolean;
  isCallJoined: boolean;
  speechRecognitionService: SpeechRecognitionService | undefined;
  isCreator: boolean;
  isAnotherJoined: boolean;
  connectionState: ConnectionState;
  roomName: string;
}

export const CHANGE_LOCAL_VIDEO_VISIBILITY = "CHANGE_LOCAL_VIDEO_VISIBILITY";
export const CHANGE_REMOTE_VIDEO_VISIBILITY = "CHANGE_REMOTE_VIDEO_VISIBILITY";
export const CHANGE_LOCAL_AUDIO_VOLUME = "CHANGE_LOCAL_AUDIO_VOLUME";
export const CLEAR_STORE = "CLEAR_STORE";
export const CHANGE_CALL_JOIN = "CHANGE_CALL_JOIN";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const CLEAR_MESSAGES = "CLEAR_MESSAGES";
export const SET_CREATOR = "SET_CREATOR";
export const CHANGE_ANOTHER_JOIN = "CHANGE_ANOTHER_JOIN";
export const INITIALIZE_SPEACH_RECOGNITION = "INITIALIZE_SPEACH_RECOGNITION";
export const CHANGE_CONNECTION_STATE = "CHANGE_CONNECTION_STATE";
export const CHANGE_ROOM_NAME = "CHANGE_ROOM_NAME";

export interface ChangeLocalVideoVisibilityAction {
  type: typeof CHANGE_LOCAL_VIDEO_VISIBILITY;
  payload: {
    isLocalVideo: boolean;
  };
}

export interface ChangeRemoteVideoVisibilityAction {
  type: typeof CHANGE_REMOTE_VIDEO_VISIBILITY;
  payload: {
    isRemoteVideo: boolean;
  };
}

export interface ChangeLocalAudioVolumeAction {
  type: typeof CHANGE_LOCAL_AUDIO_VOLUME;
  payload: {
    isLocalAudio: boolean;
  };
}

export interface ClearStoreAction {
  type: typeof CLEAR_STORE;
}

export interface ChangeCallJoinAction {
  type: typeof CHANGE_CALL_JOIN;
  payload: {
    isCallJoined: boolean;
  };
}

export interface AddMessageAction {
  type: typeof ADD_MESSAGE;
  payload: {
    key: string;
    value: string;
  };
}

export interface ClearMessagesAction {
  type: typeof CLEAR_MESSAGES;
}

export interface SetCreatorAction {
  type: typeof SET_CREATOR;
  payload: {
    isCreator: boolean;
  };
}

export interface ChangeAnotherJoinAction {
  type: typeof CHANGE_ANOTHER_JOIN;
  payload: {
    isAnotherJoined: boolean;
  };
}

export interface InitializeSpeechRecognition {
  type: typeof INITIALIZE_SPEACH_RECOGNITION;
}

export interface ChangeConnectionState {
  type: typeof CHANGE_CONNECTION_STATE;
  payload: {
    connectionState: ConnectionState;
  };
}

export interface ChangeRoomName {
  type: typeof CHANGE_ROOM_NAME;
  payload: {
    roomName: string;
  };
}

export type ChatActionTypes =
  | ChangeLocalVideoVisibilityAction
  | ChangeRemoteVideoVisibilityAction
  | ChangeLocalAudioVolumeAction
  | ClearStoreAction
  | ChangeCallJoinAction
  | AddMessageAction
  | ClearMessagesAction
  | SetCreatorAction
  | ChangeAnotherJoinAction
  | InitializeSpeechRecognition
  | ChangeConnectionState
  | ChangeRoomName;
