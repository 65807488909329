import {
  CHANGE_LOCAL_VIDEO_VISIBILITY,
  CHANGE_REMOTE_VIDEO_VISIBILITY,
  CLEAR_STORE,
  CHANGE_LOCAL_AUDIO_VOLUME,
  CHANGE_CALL_JOIN,
  ADD_MESSAGE,
  CLEAR_MESSAGES,
  SET_CREATOR,
  CHANGE_ANOTHER_JOIN,
  INITIALIZE_SPEACH_RECOGNITION,
  CHANGE_CONNECTION_STATE,
  CHANGE_ROOM_NAME,
} from "../constants/types";
import { ChatActionTypes, ChatState } from "../constants/types";
import SpeechRecognitionService from "services/speech-recognition/speechRecognitionService";
import { OrderedMap } from "immutable";
import { ConnectionState } from "../constants/types";

export const initialChatState: ChatState = {
  captions: OrderedMap(),
  username: "Anonim",
  speechRecognitionService: undefined,
  connectionState: ConnectionState.disconnected,
  isCreator: false,
  isLocalVideo: true,
  isLocalAudio: true,
  isCallJoined: false,
  isAnotherJoined: false,
  isRemoteVideo: true,
  roomName: "",
};

function chatReducer(
  state = initialChatState,
  action: ChatActionTypes
): ChatState {
  if (action.type === ADD_MESSAGE) {
    return {
      ...state,
      captions: state.captions.set(action.payload.key, action.payload.value),
    };
  }

  if (action.type === CLEAR_MESSAGES) {
    return Object.assign({}, state, { captions: OrderedMap() });
  }

  if (action.type === CHANGE_LOCAL_VIDEO_VISIBILITY) {
    return { ...state, ...action.payload };
  }

  if (action.type === CHANGE_REMOTE_VIDEO_VISIBILITY) {
    return { ...state, ...action.payload };
  }

  if (action.type === CHANGE_LOCAL_AUDIO_VOLUME) {
    return { ...state, ...action.payload };
  }

  if (action.type === CLEAR_STORE) {
    return {
      ...state,
      captions: OrderedMap(),
      // isPermissionDialog: false,
      isCallJoined: false,
      speechRecognitionService: undefined,
      isCreator: false,
      isAnotherJoined: false,
    };
  }

  // if (action.type === CHANGE_PERMISSION_DIALOG_VISIBILITY) {
  //   return { ...state, ...action.payload };
  // }

  if (action.type === CHANGE_CALL_JOIN) {
    return { ...state, ...action.payload };
  }

  if (action.type === SET_CREATOR) {
    return { ...state, ...action.payload };
  }

  if (action.type === CHANGE_ANOTHER_JOIN) {
    return { ...state, ...action.payload };
  }

  if (action.type === INITIALIZE_SPEACH_RECOGNITION) {
    return {
      ...state,
      speechRecognitionService: new SpeechRecognitionService(),
    };
  }

  if (action.type === CHANGE_CONNECTION_STATE) {
    return { ...state, ...action.payload };
  }

  if (action.type === CHANGE_ROOM_NAME) {
    return { ...state, ...action.payload };
  }

  return state;
}

export default chatReducer;
