import { useState } from "react";

const useSettings = (): { isSettings: boolean; handleSettings: () => void } => {
  const [isSettings, setIsSettings] = useState(false);
  const handleSettings = (): void => {
    setIsSettings((settings) => !settings);
  };
  return { isSettings: isSettings, handleSettings: handleSettings };
};

export { useSettings };
