import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme, Button, Dialog, useMediaQuery } from "@material-ui/core";
import Invite from "img/Invite.svg";
import ReactSvg from "react-svg";
import { ContactForm } from "./form";
import closeIcon from "img/Close.svg";
import { useTranslation } from "react-i18next";

interface StyleProps {
  isMobile: boolean;
}

const useStyles = makeStyles(() => ({
  dialogRoot: {
    "&> .MuiBackdrop-root": {
      backgroundColor: "rgba(11, 8, 27, 0.9)",
    },
  },
  button: (props: StyleProps): any => ({
    position: props.isMobile ? "static" : "absolute",
    width: props.isMobile ? "162px" : "245px",
    height: props.isMobile ? "40px" : "60px",
    right: "60px",
    bottom: "50px",
    background: " #836CF2",
    borderRadius: "30px",
    color: "white",
    textTransform: "none",
    fontSize: props.isMobile ? "14px" : "20px",
    lineHeight: "32px",
    "& svg": {
      paddingTop: props.isMobile ? "6px" : "2px",
      marginRight: props.isMobile ? "2px" : "11px",
    },
  }),
  closeIcon: {
    alignSelf: "flex-end",
    position: "absolute",
    top: 0,
    padding: "15px",
    "&:hover": {
      cursor: "pointer",
    },
    "& svg g": {
      fill: "#818895",
    },
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default function SendUsFeedback(): JSX.Element {
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const c = useStyles({ isMobile });
  const { t } = useTranslation();

  const handleDialog = (): void => {
    setOpen(!open);
  };

  return (
    <div className={c.buttonWrapper}>
      <Button className={c.button} onClick={handleDialog}>
        <ReactSvg src={Invite} /> {t("ContactForm.SendUsFeedback")}
      </Button>
      <Dialog className={c.dialogRoot} open={open}>
        <ReactSvg
          src={closeIcon}
          className={c.closeIcon}
          onClick={handleDialog}
        />
        <ContactForm />
      </Dialog>
    </div>
  );
}
