import { makeStyles, Theme } from "@material-ui/core";
import { StyleProps } from "./RemoteMediaTypes";

export const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  remoteVideo: (props: StyleProps) => ({
    position: "absolute",
    left: 0,
    top: 0,
    height: !props.isBrowser && props.isHalfMode ? "45vh" : "100vh",
    width: props.isBrowser && props.isHalfMode ? "calc(100vw - 480px)" : "100%",
    zIndex: 0
  }),
  remoteVideoThumb: (props: StyleProps) => ({
    position: "absolute",
    right: "30px",
    top: props.isBrowser ? "200px" : "140px",
    zIndex: 5,
    borderRadius: "9999px",
    "& #agora_remote": {
      overflow: "hidden",
      height: props.isBrowser ? "120px" : "60px",
      width: props.isBrowser ? "120px" : "60px",
      border: "2px solid rgba(131,108,242,1)",
      borderRadius: "9999px",
      objectFit: "cover"
    },
    "& img": {
      height: props.isBrowser ? "100px" : "60px",
      width: props.isBrowser ? "100px" : "60px",
      border: "2px solid rgba(131,108,242,1)",
      borderRadius: "9999px",
      objectFit: "cover"
    }
  }),
  ellipseShape: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    margin: "25vh auto",
    width: "28vw",
    height: "28vw",
    maxHeight: "350px",
    maxWidth: "350px",
    background: "#262D38",
    borderRadius: "50%"
  },
  innerEllipse: {
    width: "48%",
    height: "48%",
    borderRadius: "50%",
    backgroundColor: "#4A5262"
  },
  noCameraBackground: (props: StyleProps) => ({
    position: "fixed",
    top: 0,
    left: 0,
    width: props.isBrowser && props.isHalfMode ? "calc(100% - 480px)" : "100%",
    height: "100%",
    backgroundColor: "#3E4757"
  }),
  fullTextBackground: {
    background: "#E9E9E9",
    position: "fixed",
    top: 0,
    left: 0,
    height: "100vh",
    width: "100vw"
  }
}));
