import * as log from "loglevel";

export const loadState = <T>(): {
  localState: T | undefined;
  sessionState: T | undefined;
} => {
  try {
    const localStorageState = localStorage.getItem("state");
    const sessionStorageState = sessionStorage.getItem("state");
    if (sessionStorageState === null && localStorageState !== null) {
      return {
        localState: JSON.parse(localStorageState),
        sessionState: undefined,
      };
    } else if (localStorageState === null && sessionStorageState !== null) {
      return {
        sessionState: JSON.parse(sessionStorageState),
        localState: undefined,
      };
    } else if (localStorageState !== null && sessionStorageState !== null) {
      return {
        localState: JSON.parse(localStorageState),
        sessionState: JSON.parse(sessionStorageState),
      };
    } else return { localState: undefined, sessionState: undefined };
  } catch (err) {
    return { localState: undefined, sessionState: undefined };
  }
};

export const saveStateToLocalStorage = <T>(state: T): void => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (err) {
    log.error("Error with serialization: " + err);
  }
};

export const saveStateToSessionStorage = <T>(state: T): void => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem("state", serializedState);
  } catch (err) {
    log.error("Error with serialization: " + err);
  }
};
