import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";

import { mapStateToProps } from "./RemoteMediaTypes";
import { useStyles } from "./RemoteMediaStyles";

import { Theme, useMediaQuery } from "@material-ui/core";

import { Modes } from "~/../store/layout/constants/types";

const RemoteMedia: React.FC<{}> = (): JSX.Element => {
  const { mode, isCreator, isCallJoined, isAnotherJoined } = useSelector(
    mapStateToProps,
    shallowEqual
  );
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const classes = useStyles({
    isHalfMode: mode === Modes.half,
    isBrowser: !isMobile,
  });

  const [showEllipse, setShowEllipse] = useState(false);

  useEffect(() => {
    const otherUserLeft = !isAnotherJoined && !isCreator && isCallJoined;
    setShowEllipse(otherUserLeft);
  }, [isAnotherJoined, isCreator, isCallJoined]);

  return (
    <React.Fragment>
      {isAnotherJoined && (
        <div className={mode === Modes.text ? classes.fullTextBackground : ""}>
          <div className={mode === Modes.text ? classes.remoteVideoThumb : ""}>
            <div
              id="agora_remote"
              className={
                mode !== Modes.text ? classes.remoteVideo : classes.Thumb
              }
            />
          </div>
        </div>
      )}
      {showEllipse && (
        <div className={classes.noCameraBackground}>
          <div className={classes.ellipseShape}>
            <div className={classes.innerEllipse} />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default RemoteMedia;
