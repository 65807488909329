import { AppState } from "store";
import { Modes } from "~/../store/layout/constants/types";

interface StateProps {
  isLocalVideo: boolean;
  mode: Modes;
  isCreator: boolean;
}

export interface StyleProps {
  isDesktop: boolean;
  isHalfMode: boolean;
}

export const mapStateToProps = (state: AppState): StateProps => {
  return {
    isLocalVideo: state.chatState.isLocalVideo,
    mode: state.layoutState.mode,

    isCreator: state.chatState.isCreator,
  };
};
