import React, { useEffect, useRef, useState } from "react";
import { useStyles } from "./CaptionStyles";
import { Modes } from "~/../store/layout/constants/types";
import { changeCaptionsListHeight } from "store/layout/actions";
import { useDispatch } from "react-redux";
import { SpeechIndicator } from "./speech-indicator/SpeechIndicator";
import { CaptionProps } from "./CaptionTypes";
import {
  List,
  ListItem,
  ListItemText,
  Paper,
  useMediaQuery,
  Theme,
} from "@material-ui/core";

export default function CaptionComponent(props: CaptionProps): JSX.Element {
  const { mode, captions, isCallJoined } = props;
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const c = useStyles({ mode: mode, isMobile: isMobile });

  const [isAutoScroll, setAutoScroll] = useState(true);

  const listEnd = useRef<HTMLUListElement | null>(null);
  const dispatch = useDispatch();

  const lastReceivedId = captions.findLastKey((value, key) =>
    key.startsWith("[")
  );

  useEffect(() => {
    if (listEnd.current && listEnd.current.childNodes.length > 1) {
      const length: number = listEnd.current.childNodes.length;
      const captionsListHeight: number =
        (listEnd.current.childNodes[length - 1] as HTMLElement).clientHeight +
        (listEnd.current.childNodes[length - 2] as HTMLElement).clientHeight +
        22;
      dispatch(
        changeCaptionsListHeight({ captionsListHeight: captionsListHeight })
      );
    }
  });

  useEffect(() => {
    setTimeout(() => {
      if (
        (listEnd.current && isAutoScroll) ||
        (listEnd.current && mode === Modes.full)
      ) {
        listEnd.current.scroll({
          top: listEnd.current.scrollHeight,
          behavior: "smooth",
        });
      }
    }, 10);
  });

  useEffect(() => {
    if (listEnd.current) {
      listEnd.current.onscroll = (event: Event): void => {
        if (event.target instanceof Element) {
          setAutoScroll(
            mode === Modes.full ||
              event.target.clientHeight + event.target.scrollTop + 10 >=
                event.target.scrollHeight
              ? true
              : false
          );
        }
      };
    }
  });

  const createListItemRef = (
    listKey: string,
    listItemClass: string,
    paperClass: string,
    textClass: string,
    textValue: string
  ): JSX.Element => (
    <ListItem className={listItemClass} key={listKey}>
      <Paper className={paperClass}>
        <ListItemText
          classes={{ root: textClass }}
          primary={textValue}
          disableTypography
        />

        {lastReceivedId === listKey && <SpeechIndicator />}
      </Paper>
    </ListItem>
  );

  return (
    <List
      style={{
        overflow: mode === Modes.full && !isMobile ? "hidden" : "auto",
      }}
      className={c.captionList}
      ref={listEnd}
    >
      {captions.entrySeq().map((value): JSX.Element | undefined => {
        if (value[0].startsWith("[")) {
          return createListItemRef(
            value[0],
            c.captionListItemOther,
            c.captionPaperOther,
            c.captionTextOther,
            value[1]
          );
        } else {
          return createListItemRef(
            value[0],
            c.captionListItemMy,
            isCallJoined ? c.captionPaperMyDefault : c.captionPaperMyPreview,
            c.captionTextMy,
            value[1]
          );
        }
      })}
    </List>
  );
}
