import { useRef, useState, useEffect, RefObject } from "react";
import { Power0, Power1, TimelineMax } from "gsap";
import React from "react";
import StreamInterface from "../../../services/agora/stream";

export const SpeechIndicator = (): JSX.Element => {
  const circle1: RefObject<SVGCircleElement> | null | undefined = useRef(null);
  const circle2: RefObject<SVGCircleElement> | null | undefined = useRef(null);
  let timeline: any = undefined;
  const [isAnimating, setIsAnimating] = useState(false);
  const [isLocalAudioSpeaking, setLocalAudioSpeaking] = useState(true);

  useEffect(() => {
    setInterval(function () {
      let audioLevel = 0;

      if (StreamInterface.localStream.isPlaying()) {
        audioLevel = StreamInterface.localStream.getAudioLevel() || 0;
      }

      if (audioLevel > 20) {
        setLocalAudioSpeaking(true);
      } else {
        setLocalAudioSpeaking(false);
      }
    }, 100);
  }, []);

  useEffect(() => {
    if (
      isLocalAudioSpeaking &&
      !isAnimating &&
      circle1.current &&
      circle2.current
    ) {
      setIsAnimating(true);
      // eslint-disable-next-line
      timeline = new TimelineMax({})
        .fromTo(
          circle2.current,
          2,
          { autoAlpha: 1 },
          { autoAlpha: 0, ease: Power0.easeOut, repeat: 0 },
          0
        )
        .fromTo(
          circle2.current,
          2,
          { attr: { r: 60 } },
          { attr: { r: 86 }, ease: Power1.easeOut, repeat: 0 },
          0
        )
        .fromTo(
          circle1.current,
          2,
          { autoAlpha: 1 },
          { autoAlpha: 0, ease: Power0.easeOut, repeat: 0 },
          1
        )
        .fromTo(
          circle1.current,
          2,
          { attr: { r: 60 } },
          {
            attr: { r: 94 },
            ease: Power1.easeOut,
            repeat: 0,
            onComplete: (): void => {
              setIsAnimating(false);
            },
          },
          1
        );
    }
  }, [isAnimating, isLocalAudioSpeaking]);

  useEffect(() => {
    return (): void => {
      // eslint-disable-next-line
      if (circle1.current && circle2.current) timeline.kill();
    };
  }, [timeline]);

  return (
    <svg
      width="200px"
      height="200px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      preserveAspectRatio="xMidYMid"
      className="uil-ripple"
    >
      <circle
        ref={circle1}
        cx="100"
        cy="100"
        r="100"
        stroke="#9C89F5"
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
      ></circle>
      <circle
        ref={circle2}
        cx="100"
        cy="100"
        r="100"
        stroke="#9C89F5"
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
      ></circle>
    </svg>
  );
};
