import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { StyleProps } from "./CaptionTypes";
import { Modes } from "~/../store/layout/constants/types";

export const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  captionList: (props: StyleProps) => ({
    overflowY: "auto",
    maxHeight: "100%",
    "&::-webkit-scrollbar": {
      width: "0 !important",
    },
    "&> li:nth-last-of-type(2)": {
      opacity: props.mode === Modes.full && !props.isMobile ? 0.6 : 1,
    },
  }),
  ListItemText: {
    textShadow: "0px 1px 4px rgba(0, 0, 0, 0.5)",
  },

  captionListItemOther: (props: StyleProps) => ({
    justifyContent: "flex-start",
    padding:
      !props.isMobile && props.mode === Modes.half
        ? "0px 30px 20px 0px"
        : "0px 30px 10px 30px",
  }),

  captionPaperOther: (props: StyleProps) => ({
    backgroundColor: theme.customPalette.otherBubble.main,
    display: "flex",
    width: "100%",
    padding: "14px 19px",
    borderRadius: "8px",
    boxShadow: "none",
    flexDirection: "column",
    marginLeft: !props.isMobile && props.mode === Modes.half ? "100px" : "0",
  }),
  captionTextOther: (props: StyleProps) => ({
    textAlign: "end",
    color: theme.customCaptions.fontColor,
    fontFamily: theme.customCaptions.fontFamily,
    fontSize: theme.customCaptions.fontSize,
    textShadow:
      !props.isMobile && props.mode === Modes.half
        ? "none"
        : "0px 1px 4px rgba(0, 0, 0, 0.5)",
  }),

  captionListItemMy: (props: StyleProps) => ({
    justifyContent:
      !props.isMobile && props.mode === Modes.half ? "flex-end" : "center",
    padding:
      !props.isMobile && props.mode === Modes.half
        ? "0px 14px 20px 14px"
        : "0px 30px 10px 30px",
  }),

  captionPaperMyDefault: (props: StyleProps) => ({
    backgroundColor:
      !props.isMobile && props.mode === Modes.half
        ? "rgba(63, 71, 86, 0.1)"
        : theme.customPalette.myBubble.main,
    display: "flex",
    padding: "14px 19px",
    width: "100%",
    borderRadius: "8px",
    boxShadow: "none",
    marginRight: !props.isMobile && props.mode === Modes.half ? "100px" : "0",
  }),
  captionPaperMyPreview: {
    backgroundColor: "rgba(0,0,0,0.4)",
    display: "flex",
    width: "100%",
    padding: "14px 19px",
    borderRadius: "8px",
  },
  captionTextMy: (props: StyleProps) => ({
    color: !props.isMobile && props.mode === Modes.half ? "#3F4756" : "white",
    fontFamily: theme.customCaptions.fontFamily,
    fontSize: theme.customCaptions.fontSize,
    textShadow:
      !props.isMobile && props.mode === Modes.half
        ? "none"
        : "0px 1px 4px rgba(0, 0, 0, 0.5)",
  }),
}));
