/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import {
  Dialog,
  Button,
  DialogActions,
  Theme,
  Typography,
} from "@material-ui/core";
import ReactSVG from "react-svg";

import AtendeeLeft from "../../img/Atendee-left.svg";
import closeIcon from "img/Close.svg";

export interface StyleProps {
  isMobile: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  dialogRoot: {
    "&> .MuiBackdrop-root": {
      backgroundColor: "rgba(11, 8, 27, 0.9)",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0",
    },
  },
  root: (props: StyleProps) => ({
    width: props.isMobile ? "auto" : "596px",
    height: props.isMobile ? "auto" : "703px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    paddingBottom: "5vh",
    margin: props.isMobile ? "0" : "auto",
  }),
  closeIcon: {
    alignSelf: "flex-end",
    position: "absolute",
    top: 0,
    padding: "15px",
    "& svg g": {
      fill: "#818895",
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
  atendeeLeft: (props: StyleProps) => ({
    marginTop: props.isMobile ? "5vh" : "7vh",
    width: props.isMobile ? "100px" : "120px",
    margin: "0 auto",
    "& svg": {
      height: props.isMobile ? "100px" : "120px",
      width: props.isMobile ? "100px" : "120px",
    },
    "&> div": {
      height: props.isMobile ? "100px" : "120px",
      width: props.isMobile ? "100px" : "120px",
      display: "flex",
    },
  }),
  title: (props: StyleProps) => ({
    marginTop: props.isMobile ? "24px" : "7vh",
    color: "#3F4756",
    fontSize: props.isMobile ? "24px" : "36px",
    textAlign: "center",
    maxWidth: "340px",
    padding: props.isMobile ? "0 24px" : "0",
  }),
  description: (props: StyleProps) => ({
    padding: props.isMobile ? "0 24px" : "0 68px",
    marginTop: props.isMobile ? "8px" : "23px",
    fontSize: props.isMobile ? "14px" : "16px",
    lineHeight: "150%",
    textAlign: "center",
    color: "#818895",
  }),
  sendText: {
    color: theme.palette.primary.light,
  },
  callEndButton: (props: StyleProps) => ({
    color: "white",
    background: theme.palette.secondary.light,
    textTransform: "none",
    boxShadow: "0px 8px 12px rgba(131, 108, 242, 0.25)",
    bordeRadius: "8px",
    width: props.isMobile ? "100%" : "180px",
    height: "60px",
    fontSize: "16px",
    lineHeight: "19px",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "20px",
    },
    "&:hover": {
      backgroundColor: theme.palette.secondary.light + " !important",
    },
  }),
  keepGoingButton: (props: StyleProps) => ({
    backgroundColor: "transparent",
    textTransform: "none",
    display: "inline-flex",
    border: "1px solid rgba(131,108,242,0.4)",
    borderRadius: "8px",
    height: "60px",
    minWidth: props.isMobile ? "100%" : "180px",
    marginLeft: props.isMobile ? "0" : "20px",
  }),
  keepGoingText: {
    color: theme.palette.secondary.light,
  },
  dialogActions: (props: StyleProps) => ({
    marginTop: props.isMobile ? "24px" : "4vh",
    dispaly: "flex",
    flexDirection: props.isMobile ? "column" : "row",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "0 24px",
    },
  }),
  link: {
    textDecoration: "none",
    width: "100%",
  },
}));

interface StateProps {
  open: boolean;
  handleCallEnd: () => void;
  handleCallEndDialog: () => void;
}

const CallEndDialog: React.FC<StateProps> = ({
  open,
  handleCallEnd,
  handleCallEndDialog,
}: StateProps): JSX.Element => {
  const [isOpen, setOpen] = useState(open);
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const classes = useStyles({ isMobile: isMobile });

  useEffect(() => {
    setOpen(open);
  }, [open]);

  return (
    <Dialog open={isOpen} className={classes.dialogRoot}>
      <div className={classes.root}>
        <ReactSVG
          src={closeIcon}
          className={classes.closeIcon}
          onClick={handleCallEndDialog}
        />
        <div>
          <ReactSVG src={AtendeeLeft} className={classes.atendeeLeft} />
          <Typography variant="h5" className={classes.title}>
            {t("CallEndDialog.title")}
          </Typography>
        </div>
        <Typography variant="h5" className={classes.description}>
          {t("CallEndDialog.description")}
        </Typography>
        <DialogActions className={classes.dialogActions}>
          <Button
            onClick={handleCallEnd}
            color="primary"
            variant="contained"
            className={classes.callEndButton}
          >
            <Typography variant="body1">
              {t("CallEndDialog.endCall")}
            </Typography>
          </Button>

          <Button
            onClick={handleCallEndDialog}
            className={classes.keepGoingButton}
          >
            <Typography variant="body1" className={classes.keepGoingText}>
              {t("CallEndDialog.cancel")}
            </Typography>
          </Button>
        </DialogActions>{" "}
      </div>
    </Dialog>
  );
};

export default CallEndDialog;
