import React from "react";
import ReactSVG from "react-svg";

import { makeStyles } from "@material-ui/styles";
import { Select, Theme, Typography } from "@material-ui/core";

import downArrow from "img/Down-arrow.svg";

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  root: (props: StyleProps) => ({
    gridColumn: `${props.column} / span ${props.span}`,
  }),
  label: {
    color: "rgba(129,136,149,1)",
    marginBottom: "2px",
    fontSize: "12px",
    lineHeight: "24px",
  },
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  icon: (props: StyleProps) => ({
    display: props.isIcon ? "flex" : "none",
    position: "absolute",
    right: 0,
    pointerEvents: "none",
    padding: "10px",
    alignItems: "center",
    justifyContent: "center",
    borderLeft: "solid 1px rgba(238,238,238,1)",
    height: "100%",
    "& svg": {
      height: "24px",
      fill: "rgba(107,116,134,1)",
    },
    "&> div": {
      height: "24px",
      display: "flex",
    },
  }),
  settingsRowText: {
    height: "50px",
    display: "flex",
    alignItems: "center",
    padding: "0 10px",
    border: "solid 1px rgba(238,238,238,1)",
    borderRadius: "8px",
    color:
      theme.palette.type === "light"
        ? theme.palette.primary.dark
        : theme.palette.primary.light,
    "&> .MuiTypography-body2": {
      fontSize: 16,
    },
  },
  select: {
    borderRadius: "8px",
    "&:focus": {
      borderRadius: "8px",
    },
  },
}));

export interface OwnProps {
  column: number;
  span: number;
  isIcon: boolean;
  label: string;
  handleChange: Function;
  value: string;
  menuItems: Array<JSX.Element>;
}

export interface StyleProps {
  column: number;
  span: number;
  isIcon: boolean;
}

const CustomSelect: React.FC<OwnProps> = ({
  column,
  handleChange,
  isIcon,
  label,
  span,
  value,
  menuItems,
}: OwnProps): JSX.Element => {
  const classes = useStyles({ column, span, isIcon });

  const DownArrow = <ReactSVG src={downArrow} className={classes.icon} />;

  return (
    <div className={classes.root}>
      <div className={classes.label}>
        <Typography>{label}</Typography>
      </div>
      <Select
        inputProps={{
          className: classes.settingsRowText,
        }}
        disableUnderline
        fullWidth
        IconComponent={(): JSX.Element => DownArrow}
        classes={{ icon: classes.icon, select: classes.select }}
        value={value}
        onChange={(event: any): void => {
          handleChange(event.target.value);
        }}
      >
        {menuItems}
      </Select>
    </div>
  );
};

export { CustomSelect };
