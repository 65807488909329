import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";

import CallPage from "./CallPage";

import {
  changeCallJoin,
  changeAnotherJoin,
  // changeUnavailebleLinkDialog,
  initializeSpeechRecognition,
  changeConnectionState,
  setCreator,
  changeRoomName,
} from "store/chat/actions";

import { changeBrowserDialog } from "store/layout/actions";
import { changeOccupiedConversationDialog } from "store/layout/actions";
import StreamInterface from "../../services/agora/stream";
import RtmInterface from "../../services/agora/rtm";

import lostConnection from "img/Lost-connection.svg";
import reconnecting from "img/Reconnecting.svg";
import someoneIsJoingRoom from "img/someoneIsJoiningRoom.svg";
import userLeftTheRoom from "img/userLeftRoom.svg";
import serverProblems from "img/Server-problems.svg";

import {
  mapStateToProps,
  CallPageProps,
  StateProps,
  Snackbar,
} from "./CallPageTypes";
import { ConnectionState } from "../../store/chat/constants/types";

const CallPageContainer = function (): JSX.Element {
  const { history } = useReactRouter();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    isCreator,
    isCallJoined,
    isAnotherJoined,
    startedTalking,
  }: StateProps = useSelector(mapStateToProps, shallowEqual);

  const [snackbar, setSnackbar] = useState(new Snackbar(false));

  useEffect(() => {
    const isChrome = (window as any).chrome;

    if (isChrome && !window.location.href.includes("transcription=false")) {
      dispatch(initializeSpeechRecognition());
    }

    if (!isChrome) {
      dispatch(changeBrowserDialog({ isBrowserDialog: true }));
    }
  }, [dispatch]);

  useEffect(() => {
    const pathname = window.location.pathname;
    if (pathname === "/") {
      history.push("/");
    } else
      dispatch(changeRoomName({ roomName: pathname.split("/").reverse()[0] }));
  }, [history, dispatch]);

  useEffect(() => {
    let mounted = true;
    const onConnectionStateChange = function (): void {
      const connectionState = StreamInterface.getConnectionState();
      switch (connectionState) {
        case ConnectionState.connecting:
          dispatch(
            changeConnectionState({
              connectionState: ConnectionState.connecting,
            })
          );
          mounted &&
            setSnackbar(
              new Snackbar(true, t("CallPage.connecting"), reconnecting)
            );
          break;
        case ConnectionState.connected:
          dispatch(
            changeConnectionState({
              connectionState: ConnectionState.connected,
            })
          );
          break;
        case ConnectionState.disconnected:
          dispatch(
            changeConnectionState({
              connectionState: ConnectionState.disconnected,
            })
          );
          mounted &&
            setSnackbar(
              new Snackbar(true, t("CallPage.disconnected"), lostConnection)
            );
          break;
      }
    };
    StreamInterface.client.on("connection-state-change", (): void => {
      onConnectionStateChange();
    });
    StreamInterface.client.on("stream-added", (): void => {
      dispatch(changeCallJoin({ isCallJoined: true }));
    });
    StreamInterface.client.on("stream-subscribed", (evt: any): void => {
      if (Object.keys(StreamInterface.getRemoteStream()).length > 1) {
        StreamInterface.client.leave();
        dispatch(
          changeOccupiedConversationDialog({
            isOccupiedConversationDialog: true,
          })
        );
      }
    });

    StreamInterface.client.on("peer-online", (): void => {
      dispatch(changeAnotherJoin({ isAnotherJoined: true }));
      if (isCreator) {
        dispatch(setCreator({ isCreator: false }));
      }
      RtmInterface.joinChannel();
      mounted &&
        setSnackbar(
          new Snackbar(
            true,
            t("CallPage.someoneJoinedCall"),
            someoneIsJoingRoom,
            true,
            true
          )
        );
    });
    StreamInterface.client.on("peer-leave", (): void => {
      dispatch(changeAnotherJoin({ isAnotherJoined: false }));
      RtmInterface.joinChannel();
      mounted &&
        setSnackbar(
          new Snackbar(true, t("CallPage.userLeftRoom"), userLeftTheRoom)
        );
    });
    StreamInterface.client.on("error", (): void => {
      mounted &&
        setSnackbar(
          new Snackbar(true, t("CallPage.serverProblem"), serverProblems)
        );
    });
    return (): void => {
      mounted = false;
    };
  }, [t, dispatch, isCreator]);

  const callPageProps: CallPageProps = {
    isAnotherJoined,
    isCallJoined,
    snackbar,
    startedTalking,
  };
  return <CallPage {...callPageProps} />;
};

export default CallPageContainer;
