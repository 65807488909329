import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          SplashScreen: {
            madeWith: "Captio.cc made with",
            by: "by",
            allbright: "AllBright.io",
          },
          MainPage: {
            madeWith: "Captio.cc made with",
            by: "by",
            allbright: "AllBright.io",
            createRoom: "Create room",
            welcomeText: "Communicate easier with the world",
            welcomeDescription:
              "Captio, application for video calls with a real-time transcription",
            settings: "Settings",
          },
          SettingsPanel: {
            title: "App Settings",
            darkMode: "Dark mode",
            fontSize: "Text size",
            fontFamily: "Font style",
            fontColor: "Text color",
            themeColor: "Theme color",
            otherBubble: "Bubble color",
            language: "Language",
            saveSettings: "Save Settings",
            privacyPolicy: {
              title: "Privacy Policy",
              subTitlePurple: "LAST UPDATED: MAY 15",
              message1:
                "Marcel for Art Ltd respects your right to privacy. This Privacy Policy explains who we are, how we collect, share and use personal information about you, and how you can exercise your privacy rights. This Privacy Policy only applies to personal information          that we collect through our website at marcelforart.com ieand our mobile applications (collectively, the “Sites”). This Privacy Policy applies to you if you use our Sites, with specific provisions applicable only to EEA residents as noted below.",
              message2:
                "If you have any questions or concerns about our use of your personal information, then please contact us using the contact",
              button: "Return",
            },
            fontSizes: {
              large: "Large",
              medium: "Medium",
              small: "Small",
            },
            fontColors: {
              white: "White",
              black: "Black",
              blue: "Blue",
              green: "Green",
              yellow: "Yellow",
              orange: "Orange",
              red: "Red",
            },
            themeColors: {
              dark: "Dark",
              light: "Light",
            },
            otherBubbleColors: {
              purple: "Purple",
              white: "White",
              black: "Black",
              blue: "Blue",
              green: "Green",
              yellow: "Yellow",
              orange: "Orange",
              red: "Red",
            },
            languagesOptions: {
              en: "English",
              pl: "Polish",
            },
          },
          OnboardCarousel: {
            improveFlowTitle: "Improve your messaging flow with clients",
            improveFlowDescription:
              "Captio allows you to communicate easily and quickly using transcription",
            createRoomTitle: "Create a room",
            createRoomDescription:
              "Captio allows you to communicate easily and quickly using transcription",
            inviteFriendTitle: "Invite a friend",
            inviteFriendDescription:
              "Captio allows you to communicate easily and quickly using transcription",
            videoChatTitle: "Video Chat",
            videoChatDescription:
              "Captio allows you to communicate easily and quickly using transcription",
            settingsTitle: "Settings",
            settingsDescription:
              "Captio allows you to communicate easily and quickly using transcription",
            continue: "Continue",
            skip: "Skip",
          },
          ShareDialog: {
            linkTitle: "Your link",
            linkCopy: "Copy to clipboard",
            sendInvitation: "Send invitation",
            linkCopied: "Link copied!",
            shareMessageTitle: "Captio link",
            shareMessageText:
              "Hello! Join my conversation on captio with the link below!",
            enableTranscription: "Enable Transcription",
          },
          CallPage: {
            previewTitle: "Start talking to test transcription feature...",
            someoneJoinedCall: "Someone is joining your room...",
            userTimedOut: "User timed out...",
            connecting: "Rennecting...",
            disconnected: "Lost connection...",
            connected: "Connected",
            userLeftRoom: "User left room..",
            serverProblem: "Server problem, reconnecting..",
          },
          TipsCarousel: {
            firstViewTitle: "Starting your conversation...",
            firstViewDescription1:
              "If you finally tested our app and everything is working out you can invite people to your room, just click to ",
            firstViewDescription2: " button below.",
          },
          PermissionDialog: {
            dialogTitle: "Camera and microphone",
            dialogDescription:
              "Let us use camera and microphone so you can begin video chat with captions! Please change permission settings in the left menu in your search bar.",
            dialogOk: "Ok",
          },
          CallControl: {
            invite: "Invite",
            join: "Join room",
            settings: "Settings",
            back: "Go back",
            joiningRoom: "Joining to room...",
            someone: "Someone",
            someouneJoiningRoom: "is joining into your room..",
          },
          BrowserDialog: {
            incopatibleBrowser: "Incompatible Browser",
            descriptionStart:
              "Sorry, but currently Captio only works in Google Chrome",
            descriptionEnd:
              "You can still look around and play with settings, but in order to start using our app, you’ll have to switch to Google Chrome",
            googleChrome: "Google Chrome",
            keepGoing: "Keep going anyway",
          },
          UnavailableLinkDialog: {
            title: "Your link is no longer available",
            descriptionStart: "Sorry, but this link is not working anymore.",
            descriptionEnd:
              "There might couple of reasons for example user left our app dolor sit amet consectutar domor at elis etc.",
            cancel: "Cancel",
          },
          OccupiedConversationDialog: {
            title: "Someone is already in this conversation",
            description:
              "Sorry, someone is already talking in this channel. Contact the person who shared the link",
            cancel: "Cancel",
          },
          CallEndDialog: {
            title: "Are you sure you want to end this call?",
            description: "Your chat history will not be saved",
            endCall: "End this call",
            cancel: "Cancel",
          },
          CookiesSnackbar: {
            message:
              "This site uses cookies to provide the best possible service. See ours",
            privacyPolicy: "Privacy Policy",
          },
          ContactForm: {
            thankYou: "Thank you for your massage",
            doYouHaveAnyFeedback: "Would you like to share feedback?",
            name: "Name",
            surname: "Surname",
            email: "Email",
            telephone: "Telephone",
            enter: "Enter",
            feedback: "Feedback",
            send: "Send",
            messageContent: "Message Content",
            failedToSendForm: "Failed to send Form",
            SendUsFeedback: "Send us feedback",
          },
          Badge: {
            message: "Preview",
          },
        },
      },
      pl: {
        translation: {
          SplashScreen: {
            madeWith: "Captio.cc wykonane z",
            by: "przez",
            allbright: "AllBright.io",
          },
          MainPage: {
            madeWith: "Captio.cc wykonane z",
            by: "przez",
            allbright: "AllBright.io",
            createRoom: "Stwórz pokój",
            welcomeText: "Witamy w Captio",
            welcomeDescription:
              "Captio pozwala Ci na łatwą i szybką komunikację z użyciem napisów",
            settings: "Ustawienia",
          },
          SettingsPanel: {
            title: "Ustawienia Aplikacji",
            darkMode: "Tryb ciemny",
            fontSize: "Wielkość czcionki",
            fontFamily: "Rodzaj czcionki",
            fontColor: "Kolor czcionki",
            themeColor: "Kolor motywu",
            otherBubble: "Kolor dymku",
            language: "Język",
            saveSettings: "Zapisz Ustawienia",
            privacyPolicy: {
              title: "Polityka Prywatności",
              subTitlePurple: "LAST UPDATED: MAY 15",
              message1:
                "Marcel for Art Ltd respects your right to privacy. This Privacy Policy explains who we are, how we collect, share and use personal information about you, and how you can exercise your privacy rights. This Privacy Policy only applies to personal information          that we collect through our website at marcelforart.com ieand our mobile applications (collectively, the “Sites”). This Privacy Policy applies to you if you use our Sites, with specific provisions applicable only to EEA residents as noted below.",
              message2:
                "If you have any questions or concerns about our use of your personal information, then please contact us using the contact",
              button: "Powrót",
            },
            fontSizes: {
              large: "Duża",
              medium: "Średnia",
              small: "Mała",
            },
            fontColors: {
              white: "Biały",
              black: "Czarny",
              blue: "Niebieski",
              green: "Zielony",
              yellow: "Żółty",
              orange: "Pomarańczowy",
              red: "Czerwony",
            },
            themeColors: {
              dark: "Ciemny",
              light: "Jasny",
            },
            otherBubbleColors: {
              purple: "Fioletowy",
              white: "Biały",
              black: "Czarny",
              blue: "Niebieski",
              green: "Zielony",
              yellow: "Żółty",
              orange: "Pomarańczowy",
              red: "Czerwony",
            },
            languagesOptions: {
              en: "Angielski",
              pl: "Polski",
            },
          },
          OnboardCarousel: {
            improveFlowTitle: "Popraw komunikację z klientami",
            improveFlowDescription:
              "Captio pozwala Ci na łatwą i szybką komunikację z użyciem napisów",
            createRoomTitle: "Załóż pokój",
            createRoomDescription:
              "Captio pozwala Ci na łatwą i szybką komunikację z użyciem napisów",
            inviteFriendTitle: "Zaproś znajomego",
            inviteFriendDescription:
              "Captio pozwala Ci na łatwą i szybką komunikację z użyciem napisów",
            videoChatTitle: "Video Chat",
            videoChatDescription:
              "Captio pozwala Ci na łatwą i szybką komunikację z użyciem napisów",
            settingsTitle: "Settings",
            settingsDescription:
              "Captio pozwala Ci na łatwą i szybką komunikację z użyciem napisów",
            continue: "Kontynuuj",
            skip: "Pomiń",
          },
          ShareDialog: {
            linkTitle: "Twój link",
            linkCopy: "Skopiuj do schowka",
            sendInvitation: "Wyślij zaproszenie",
            linkCopied: "Link skopiowany!",
            shareMessageTitle: "Link do captio",
            shareMessageText:
              "Cześć! Dołącz do mojej konwersacji na captio przy pomocy linku poniżej!",
            enableTranscription: "Włącz transkrypcje",
          },
          CallPage: {
            previewTitle: "Zacznij mówić, by przetestować transkrypcję...",
            someoneJoinedCall: "Ktoś dołączył do rozmowy...",
            userTimedOut: "Użytkownik został rozłączony...",
            connecting: "Łączenie...",
            disconnected: "Utracono połączenie...",
            connected: "Połączono",
            userLeftRoom: "User left room..",
            serverProblem: "Server problem, reconnecting..",
          },
          TipsCarousel: {
            firstViewTitle: "Zaczynamy rozmowę...",
            firstViewDescription1:
              "Jeśli przetestowałeś naszą aplikację i wszystko działa poprawnie, możesz zaprosić innych do Twojego pokoju, wystarczy kliknąć ",
            firstViewDescription2: " przycisk poniżej.",
          },
          PermissionDialog: {
            dialogTitle: "Kamera i mikrofon",
            dialogDescription:
              "Pozwól nam użyć kamery i mikrofonu, byś mógł rozpocząć wideo czat z napisami! Zmień ustawienia w lewym menu w pasku wyszukiwania.",
            dialogOk: "Ok",
          },
          CallControl: {
            invite: "Zaproś",
            join: "Dołącz",
            settings: "Ustawienia",
            back: "Cofnij",
            joiningRoom: "Łączenie z pokojem...",
            someone: "Ktoś",
            someouneJoiningRoom: "łączy się z Twoim pokojem..",
          },
          BrowserDialog: {
            incopatibleBrowser: "Niekompatybilna przeglądarka",
            descriptionStart:
              "Przepraszamy ale obecnie Captio działa wyłącznie na Google Chrome",
            descriptionEnd:
              "Nadal mozesz kontynuować i zmieniać ustawienia, ale jeśli chcesz korzystać z naszej aplikacji musisz zainstalować Google Chrome",
            googleChrome: "Google Chrome",
            keepGoing: "Keep going anyway",
          },
          UnavailableLinkDialog: {
            title: "Twój link nie jest aktywny",
            descriptionStart: "Przepraszamy, ale Twój link jużnie działa",
            descriptionEnd:
              "Może być kilka powodów, na przykład rozmówca mógł opuścić aplikację dolor sit amet consectutar domor at elis etc.",
            cancel: "Anuluj",
          },
          OccupiedConversationDialog: {
            title: "Ktoś już dołączył dokonwersacji",
            description:
              "Przepraszamy, ale ktoś już jest na tym kanale. Skontaktuj się z osobą która była nadawcą tego linku.",
            cancel: "Anuluj",
          },
          CallEndDialog: {
            title: "Czy jesteś pewny, że chceesz zakończyć rozmowę?",
            description: "Twoja historia czatu nie będzie  zachowana",
            endCall: "Zakończ rozmowę",
            cancel: "Anuluj",
          },
          CookiesSnackbar: {
            message:
              "Aby zapewnić najlepszą możliwą obsługę,strona używa plików cookie. Zobacz naszą",
            privacyPolicy: "Politykę Prywatności",
          },
          ContactForm: {
            thankYou: "Dziękujemy za wiadomość",
            doYouHaveAnyFeedback: "Czy chciałbyś podzielić się opinią?",
            name: "Imię",
            surname: "Nazwisko",
            email: "Email",
            telephone: "Telefon",
            enter: "Wpisz",
            feedback: "Opinia",
            send: "Wyślij",
            messageContent: "Treść Wiadomości",
            failedToSendForm: "Nie udało się wysłać  wiadomośći",
            SendUsFeedback: "Wyślij opinię",
          },
          Badge: {
            message: "Podgląd",
          },
        },
      },
    },
  });

export default i18n;
