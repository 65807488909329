import { changePermissionDialogVisibility } from "store/layout/actions";
import { AppState } from "store";

interface StateProps {
  isPermissionDialog: boolean;
}

export const mapStateToProps = (state: AppState): StateProps => {
  return {
    isPermissionDialog: state.layoutState.isPermissionDialog,
  };
};

export const mapDispatchToProps = {
  changePermissionDialogVisibility,
};
