import { Theme, makeStyles } from "@material-ui/core";
import { StyleProps } from "./SettingsPanelTypes";

export const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  drawerRoot: {
    backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#0B081B",
    width: "100vw"
  },
  dialogRoot: {
    maxHeight: "90%",
    width: "596px",
    maxWidth: "90%",
    borderRadius: "8px"
  },
  dialog: {
    "&> .MuiBackdrop-root": {
      backgroundColor: "rgba(11, 8, 27, 0.9)"
    }
  },
  toolbar: (props: StyleProps) => ({
    margin: props.isMobile ? "0px" : "6px 6px 0 6px"
  }),
  settingsContent: (props: StyleProps) => ({
    display: "grid",
    gridTemplateColumns: "0 1fr 1fr 0",
    gridAutoRows: "75px",
    gridRowGap: "22px",
    gridColumnGap: props.isMobile ? "12px" : "30px"
  }),
  titleText: {
    color: "#3F4756",
    fontWeight: 400,
    flexGrow: 1,
    fontSize: 24
  },
  titleIcon: {
    "&:hover": {
      cursor: "pointer"
    },
    "& svg": {
      height: "14px"
    },
    "& g": {
      // fill:
    },
    "&> div": {
      height: "14px",
      display: "flex"
    }
  },
  closeIcon: {
    "&:hover": {
      cursor: "pointer"
    },
    "& svg g": {
      fill: "#818895"
    }
  },
  descriptionRow: {
    gridColumn: "2 / span 2",
    color: "rgba(129,136,149,1)"
  },
  settingsRowRoot: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  settingsRowItem: {
    display: "inline-flex",
    margin: "10px"
  },

  settingsButtonRoot: {
    width: "60px",
    height: "60px"
  },
  settingsRowText: {
    color:
      theme.palette.type === "light"
        ? theme.palette.primary.dark
        : theme.palette.primary.light
  },
  dialogFooter: (props: StyleProps) => ({
    display: "flex",
    padding: props.isMobile ? "30px 12px 10px 12px" : "30px 30px 10px",
    justifyContent: props.isPrivacyPolicy ? "flex-end" : "space-between",
    alignItems: "flex-end",
    height: props.isMobile ? "auto" : "100%",
    flexWrap: "wrap"
  }),
  saveButton: {
    backgroundColor: theme.palette.secondary.light,
    height: "50px",
    textTransform: "none",
    display: "block",
    minWidth: "166px",
    marginBottom: "20px",
    "&:hover": {
      backgroundColor: theme.palette.secondary.light + " !important"
    }
  },
  saveButtonText: {
    color: theme.palette.primary.light,
    fontWeight: 600
  },
  privacyButtonText: {
    marginLeft: "10px",
    fontWeight: 600,
    color: "#6B7486",
    textTransform: "none"
  },
  privacyButton: {
    display: "flex",
    alignItems: "center",
    height: "50px",
    minWidth: "166px",
    marginBottom: "20px"
  },
  rightArrowIcon: {
    "& svg": {
      marginTop: "7px"
    }
  },
  privacyPolicy: (props: StyleProps) => ({
    border: "1px solid #EEEEEE",
    boxSizing: "border-box",
    borderRadius: "8px",
    margin: props.isMobile ? "0 12px" : "0 30px"
  }),
  privacyContent: {
    fontSize: "14px",
    lineHeight: "24px",
    height: "360px",
    maxHeight: "50vh",
    overflowY: "scroll",
    margin: "28px 15px 32px 30px",
    paddingRight: "15px",
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
      backgroundColor: "#EAEAEA"
    },
    "&::-webkit-scrollbar": {
      width: "4px",
      backgroundColor: "#EAEAEA"
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      backgroundColor: "#808896"
    }
  },
  privacyLastUpdated: {
    color: "#836CF2",
    marginBottom: "30px"
  },
  privacyParagraph: {
    marginBottom: "30px",
    color: "#818895"
  }
}));
