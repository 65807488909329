import {
  CHANGE_PERMISSION_DIALOG_VISIBILITY,
  CHANGE_ONBOARDING,
  CHANGE_TIPS,
  CHANGE_MODE,
  SET_SPLASH_SCREEN,
  CHANGE_CAPTIONS_LIST_HEIGHT,
  CHANGE_UNAVAILABLE_LINK_DIALOG,
  CHANGE_OCCUPIED_CONVERSATION_DIALOG,
  CHANGE_BROWSER_DIALOG,
} from "../constants/types";
import { LayoutActionTypes, LayoutState } from "../constants/types";
import { Modes } from "../constants/types";

export const initialLayoutState: LayoutState = {
  mode: Modes.full,
  isOnboarding: true,
  isTips: true,
  isSplashScreen: true,
  isPermissionDialog: false,
  isUnavailableLinkDialog: false,
  isOccupiedConversationDialog: false,
  isBrowserDialog: false,
  captionsListHeight: 230,
};

function chatReducer(
  state = initialLayoutState,
  action: LayoutActionTypes
): LayoutState {
  if (action.type === CHANGE_PERMISSION_DIALOG_VISIBILITY) {
    return { ...state, ...action.payload };
  }

  if (action.type === CHANGE_ONBOARDING) {
    return { ...state, ...action.payload };
  }

  if (action.type === CHANGE_TIPS) {
    return { ...state, ...action.payload };
  }

  if (action.type === CHANGE_MODE) {
    return { ...state, ...action.payload };
  }

  if (action.type === SET_SPLASH_SCREEN) {
    return { ...state, ...action.payload };
  }

  if (action.type === CHANGE_CAPTIONS_LIST_HEIGHT) {
    return { ...state, ...action.payload };
  }

  if (action.type === CHANGE_UNAVAILABLE_LINK_DIALOG) {
    return { ...state, ...action.payload };
  }

  if (action.type === CHANGE_OCCUPIED_CONVERSATION_DIALOG) {
    return { ...state, ...action.payload };
  }

  if (action.type === CHANGE_BROWSER_DIALOG) {
    return { ...state, ...action.payload };
  }

  return state;
}

export default chatReducer;
