import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { store } from "./store";
import "./i18n";

window.store = store;

declare global {
  interface Window {
    webkitSpeechRecognition: SpeechRecognition;
    webkitSpeechGrammarList: SpeechGrammarList;
    webkitSpeechRecognitionEvent: SpeechRecognitionEvent;
    SpeechRecognition: SpeechRecognition;
    SpeechGrammarList: SpeechGrammarList;
    SpeechRecognitionEvent: SpeechRecognitionEvent;
  }
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

serviceWorker.register({
  onUpdate: (registration: ServiceWorkerRegistration): void => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener(
        "statechange",
        (event: any): void => {
          if (event && event.target && event.target.state === "activated") {
            window.location.reload();
          }
        }
      );
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
  }
});
