import React, { useRef, useEffect, useState } from "react";
import { TimelineMax, TweenMax } from "gsap";
import { useSelector, shallowEqual } from "react-redux";

import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

import { mapStateToProps, StyleProps } from "./SpeechIndicatorTypes";

import StreamInterface from "../../../../services/agora/stream";

const constAnimationRepeat = 2;
const dotTime = 0.25;
const dotMinSize = 4;
const dotMaxSize = 8;

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  dotsContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },

  dotWrapper: {
    width: dotMaxSize + "px",
    height: dotMaxSize + "px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 2.5px",
  },

  dot: (props: StyleProps): any => ({
    width: dotMinSize + "px",
    height: dotMinSize + "px",
    backgroundColor: props.dotColor,
    borderRadius: "50px",
  }),
}));

const SpeechIndicator: React.FC<{}> = (): JSX.Element => {
  const dot1Ref = useRef<HTMLDivElement>(null);
  const dot2Ref = useRef<HTMLDivElement>(null);
  const dot3Ref = useRef<HTMLDivElement>(null);
  const timeline = useRef(new TimelineMax({ repeat: constAnimationRepeat }));
  const { dotColor } = useSelector(mapStateToProps, shallowEqual);
  const classes = useStyles({ dotColor: dotColor });
  const [isAnimating, setIsAnimating] = useState(false);
  const [isRemoteSpeaking, setRemoteSpeaking] = useState(false);

  useEffect(() => {
    StreamInterface.client.on("volume-indicator", function (evt) {
      evt.attr.forEach(function (volume) {
        if (volume.level > 3) {
          setRemoteSpeaking(true);
          setTimeout(() => setRemoteSpeaking(false), 2000);
        } else {
          setRemoteSpeaking(false);
        }
      });
    });
  }, []);

  useEffect(() => {
    if (dot1Ref.current && dot2Ref.current && dot3Ref.current) {
      const copyTimeline = timeline.current;
      timeline.current
        .add(
          TweenMax.to(dot1Ref.current, dotTime, {
            width: dotMaxSize,
            height: dotMaxSize,
            repeat: 1,
            yoyo: true,
            delay: 0.5,
          })
        )
        .add(
          TweenMax.to(dot2Ref.current, dotTime, {
            width: dotMaxSize,
            height: dotMaxSize,
            repeat: 1,
            yoyo: true,
          }),
          "-=0.3"
        )
        .add(
          TweenMax.to(dot3Ref.current, dotTime, {
            width: dotMaxSize,
            height: dotMaxSize,
            repeat: 1,
            yoyo: true,
          }),
          "-=0.4"
        )
        .pause(true);

      timeline.current.eventCallback("onComplete", (): any => {
        setIsAnimating(false);
      });
      return (): void => {
        copyTimeline.kill();
      };
    }
  }, [timeline]);

  useEffect(() => {
    if (!isAnimating && timeline.current && isRemoteSpeaking) {
      setIsAnimating(true);
      timeline.current.restart();
    }
  }, [isAnimating, isRemoteSpeaking]);

  return (
    <div className={classes.dotsContainer}>
      <div className={classes.dotWrapper}>
        <div className={classes.dot} ref={dot1Ref} />
      </div>
      <div className={classes.dotWrapper}>
        <div className={classes.dot} ref={dot2Ref} />
      </div>
      <div className={classes.dotWrapper}>
        <div className={classes.dot} ref={dot3Ref} />
      </div>
    </div>
  );
};

export { SpeechIndicator };
