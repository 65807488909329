import React from "react";
import ReactSVG from "react-svg";
import { useTranslation } from "react-i18next";
import { isBrowser, isMobile } from "react-device-detect";

import { makeStyles } from "@material-ui/styles";
import {
  Theme,
  Typography,
  Button,
  IconButton,
  useMediaQuery,
} from "@material-ui/core";
import heartIcon from "img/heart.svg";

import settingsIcon from "img/Settings.svg";
import logo from "img/Logo.svg";
import logoCaptio from "img/Capt.io.svg";
import SendUsFeedback from "./send-feedback/SendUsFeedback";
import InfoBadge from "../common/InfoBadge";
interface Props {
  handleCreate: () => void;
  handleSettings: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
  },
  content: {
    padding: isMobile ? "12px" : "0",
    height: isMobile ? "100%" : "auto",
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "834px",
    zIndex: 2,
  },
  logoDiv: {
    margin: "auto",
    color: "rgba(63,71,86,1)",
    display: "flex",
    alignItems: "center",
    marginBottom: "40px",
    position: "relative",
    "&> span": {
      position: "absolute",
      top: "-10px",
      right: "-20px",
    },
  },
  logoIcon: {
    height: "69px",
    width: "72px",
    marginRight: "15px",
    "& svg": {
      height: "69px",
      width: "72px",
    },
    "& g": {
      fill: "#836CF2",
    },
    "&> div": {
      height: "47px",
      display: "flex",
    },
  },
  logoCaptio: {
    marginTop: "15px",
    height: "49px",
    width: "122px",
    "&> div": {
      "& svg path": {
        height: "49px",
        width: "122px",
      },
    },
  },
  welcomeText: {
    textAlign: "center",
    margin: "auto",
    fontWeight: 400,
    color: "rgba(63,71,86,1)",
    marginBottom: "20px",
  },
  descriptionText: {
    color: "rgba(129,136,149,1)",
    marginBottom: "40px",
    fontSize: isMobile ? "22px" : "28px",
    lineHeight: isMobile ? "38px" : "48px",
    textAlign: "center",
    fontWeight: 100,
  },
  createRoomButton: {
    margin: "auto",
    width: "315px",
    maxWidth: "calc(100vw - 24px)",
    minHeight: "70px",
    textTransform: "none",
    borderRadius: "8px",
    boxShadow: "0px 8px 12px rgba(255, 146, 166, 0.25)",
  },
  createRoomText: {
    color: theme.palette.primary.light,
  },
  settingsDiv: {
    position: "fixed",
    top: "50px",
    left: "60px",
    border: "1px solid #EBECEE",
    borderRadius: "30px",
    height: "60px",
    width: "170px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "20px",
    color: "#818895",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgb(228, 228, 228)",
    },
  },
  settingsButton: {
    border: isMobile ? "1px solid rgba(235,236,238,1)" : "unset",
    display: "inline-flex",
    height: isMobile ? "60px" : "fit-content",
    width: isMobile ? "60px" : "fit-content",
    borderRadius: "30px",
    justifyContent: "center",
    alignItems: "center",
    padding: isMobile ? "0px" : "0px 10px 0px 0px",
    margin: isMobile ? "18px 0 0 18px" : "0",
  },
  settingsIcon: {
    borderRadius: "20px",
    "& svg": {
      height: "24px",
      width: "24px",
    },
    "& g": {
      fill: "#818895",
    },
    "&> div": {
      height: "24px",
      display: "flex",
    },
  },
  madeWithLine: {
    marginTop: "1vh",
    fontSize: "18px",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    color: "#818895",
    marginBottom: "38px",
  },
  madeWith: {
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  heartIcon: {
    margin: "4px 4px 0 4px",
    "&> div": {
      "& svg": {
        width: "20px",
        height: "18px",
      },
    },
  },
  companyName: {
    marginLeft: "4px",
    fontWeight: "bold",
    color: "#3F4756",
  },
}));

const MainPageContent: React.FC<Props> = ({
  handleCreate,
  handleSettings,
}: Props): JSX.Element => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const c = useStyles({ isMobile });
  const { t } = useTranslation();

  return (
    <div className={c.root}>
      {!isMobile && (
        <div className={c.settingsDiv} onClick={handleSettings}>
          <IconButton disabled className={c.settingsButton}>
            <ReactSVG src={settingsIcon} className={c.settingsIcon} />
          </IconButton>
          <Typography variant="h6">{t("MainPage.settings")}</Typography>
        </div>
      )}

      <div className={c.content}>
        {isMobile && (
          <div>
            <IconButton onClick={handleSettings} className={c.settingsButton}>
              <ReactSVG src={settingsIcon} className={c.settingsIcon} />
            </IconButton>
          </div>
        )}
        <div className={c.logoDiv}>
          <ReactSVG src={logo} className={c.logoIcon} />
          <ReactSVG src={logoCaptio} className={c.logoCaptio} />
          <InfoBadge />
        </div>
        <div className={c.madeWithLine}>
          <div className={c.madeWith}>
            {t("MainPage.madeWith")}
            <ReactSVG className={c.heartIcon} src={heartIcon} />
            {t("MainPage.by")}
            <span className={c.companyName}>{t("MainPage.allbright")}</span>
          </div>
        </div>
        <Typography className={c.welcomeText} variant="h4">
          {t("MainPage.welcomeText")}
        </Typography>
        <Typography className={c.descriptionText} variant="h6">
          {t("MainPage.welcomeDescription")}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreate}
          className={c.createRoomButton}
        >
          <Typography
            className={c.createRoomText}
            variant={isBrowser ? "h6" : "body1"}
          >
            {t("MainPage.createRoom")}
          </Typography>
        </Button>
        <SendUsFeedback />
      </div>
    </div>
  );
};

export { MainPageContent };
