import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { isMobile } from "react-device-detect";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: "hidden",
    backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#0B081B",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  captionsPreview: {
    position: "absolute",
    top: "22vh",
    textAlign: "center",
    left: "50vw",
    maxWidth: "500px",
    transform: "translate(-50%, 0)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  logoDiv: {
    display: "flex",
    height: "127px",
    width: "127px",
    border: "1px solid rgba(157,140,245,0.1)",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    "& div": {
      display: "flex",
      height: "99px",
      width: "99px",
      border: "1px solid rgba(157,140,245,0.4)",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
      "& svg": {
        height: "47px",
        width: "48px"
      },
      "& g": { fill: theme.palette.secondary.light }
    }
  },
  captionsPreviewText: {
    fontSize: isMobile ? "20px" : "36px",
    lineHeight: isMobile ? "24px" : "48px",
    marginTop: "20px",
    color:
      theme.palette.type === "dark" ? theme.palette.primary.light : "#818895"
  }
}));
