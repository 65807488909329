import { setCreator, clearStore } from "store/chat/actions";
import { AppState } from "store";

interface StateProps {
  isOnboarding: boolean;
}

export const mapStateToProps = (state: AppState): StateProps => {
  return {
    isOnboarding: state.layoutState.isOnboarding,
  };
};

export const mapDispatchToProps = {
  setCreator,
  clearStore,
};
