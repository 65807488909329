import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import { StyleProps } from "./TipsCarouselTypes";

export const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  root: (props: StyleProps) => ({
    backgroundImage: "linear-gradient(135deg, #FF71A3 0%, #FFA0A7 100%);",
    borderRadius: "8px",
    display: "flex",
    flex: "0 0 187px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: "0 8px 12px 0 rgba(255,146,166,0.25)",
    width: props.isMobile ? "90vw" : props.isFullMode ? "653px" : "430px",
    margin: props.isMobile ? "0" : "0 auto"
  }),
  swipeableView: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center"
  },
  titleText: (props: StyleProps) => ({
    margin: props.isMobile ? "6px 12px" : "18px 20px 6px 20px",
    fontSize: props.isMobile ? "20px" : "24px",
    color:
      theme.palette.type === "light"
        ? theme.palette.primary.light
        : theme.palette.primary.dark,
    marginBottom: "8px"
  }),
  descriptionText: (props: StyleProps) => ({
    padding: props.isMobile ? "0px 12px 4px 12px" : "0px 20px 16px 20px",
    width: props.isMobile ? "50%" : props.isFullMode ? "100%" : "70%",
    fontSize: props.isMobile ? "14px" : "20px",
    lineHeighT: props.isMobile ? "16px" : "32px",
    fontWeight: 300,
    color:
      theme.palette.type === "light" ? "#FFE1E3" : theme.palette.primary.dark
  }),
  controlDiv: {
    borderTop: "1px solid rgba(255, 255, 255, 0.15)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "49px"
  },
  defaultDot: {
    height: "8px",
    width: "8px",
    borderRadius: "999px",
    marginRight: "12px",
    backgroundColor:
      theme.palette.type === "light"
        ? "rgba(255,255,255,0.3)"
        : "rgba(0,0,0,0.3)",
    display: "inline-block"
  },
  activeDot: {
    backgroundColor:
      theme.palette.type === "light" ? "rgba(255,255,255,1)" : "rgba(0,0,0,1)"
  },
  buttonNext: (props: StyleProps) => ({
    marginRight: props.isMobile ? "12px" : "20px"
  }),
  nextIcon: {
    "&> div": {
      height: "12px",
      display: "flex"
    },
    "& svg": {
      height: "12px",
      width: "12px"
    },
    "& g": {
      fill:
        theme.palette.type === "light"
          ? theme.palette.primary.light
          : theme.palette.primary.dark
    }
  },
  buttonClose: (props: StyleProps) => ({
    marginLeft: props.isMobile ? "12px" : "20px"
  }),
  closeIcon: {
    "&> div": {
      height: "12px",
      display: "flex"
    },
    "& svg": {
      height: "12px",
      width: "12px"
    },
    "& g": {
      fill:
        theme.palette.type === "light"
          ? theme.palette.primary.light
          : theme.palette.primary.dark
    }
  },
  inviteIcon: (props: StyleProps) => ({
    margin: "0 4px",
    display: "inline-flex",
    "& svg": {
      height: props.isMobile ? "15px" : "20px",
      width: props.isMobile ? "15px" : "21px"
    },
    "&> div": {
      height: "15px",
      display: "flex"
    },
    "& g": {
      fill:
        theme.palette.type === "light"
          ? theme.palette.primary.light
          : theme.palette.primary.dark
    }
  })
}));
