import React from "react";
import { makeStyles } from "@material-ui/core";
import { Theme, Typography, Badge } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles<Theme>(() => ({
  badge: {
    padding: "1px 4px",
    background: "rgb(131, 108, 242)",
    borderRadius: "5px",
  },
  text: {
    color: "white",
    fontWeight: 800,
    position: "relative",
  },
}));

const InfoBadge = (): JSX.Element => {
  const c = useStyles();
  const { t } = useTranslation();

  return (
    <Badge className={c.badge}>
      <Typography className={c.text}>{t("Badge.message")}</Typography>
    </Badge>
  );
};

export default InfoBadge;
