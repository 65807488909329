import React from "react";

import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

interface Props {
  dotNumber: number;
  sliderNumber: number;
}

interface StyleProps {
  isActiveDot: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  dot: (props: StyleProps): any => ({
    height: "8px",
    width: props.isActiveDot ? "30px" : "8px",
    borderRadius: "999px",
    marginRight: "11px",
    backgroundColor: props.isActiveDot
      ? "rgba(86,70,206,1)"
      : "rgba(206,200,253,1)",
    display: "inline-block",
  }),
}));

const Dot: React.FC<Props> = ({
  dotNumber,
  sliderNumber,
}: Props): JSX.Element => {
  const classes = useStyles({ isActiveDot: dotNumber === sliderNumber });

  return <div className={classes.dot} />;
};

export { Dot };
